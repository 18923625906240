import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
interface location {
  latitude: number;
  longitude: number;
}
const GetAddress = (props: any) => {
  const [location, setLocation] = useState(null);
  let geocode = props.location as location;

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${geocode?.latitude}&lon=${geocode?.longitude}&format=json`
        );
        const data = await response.json();
        // Extract the formatted address from the response
        const address = data.display_name;
        setLocation(address);
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();

    // Cleanup function for useEffect
    return () => {
      setLocation(null); // Clear the location when component unmounts
    };
  }, [geocode?.latitude, geocode?.longitude]);

  return (
    <>{location ? location : <CircularProgress color="warning" size={15} />}</>
  );
};

export default GetAddress;
