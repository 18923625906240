import React, { useEffect, useState, useContext, useCallback } from "react";
import styled from "styled-components/macro";
import { darken } from "polished";
import { tableCellClasses } from "@material-ui/core/TableCell";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";
import {
  CircularProgress,
  Avatar,
  Button,
  Dialog,
  Chip,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Actions from "./Actions";
import { GlobalContext } from "../../contexts/GlobalContext";
import { spacing } from "@material-ui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
  NotInterested as SuspendIcon,
  Clear as ClearIcon,
  VerifiedUser as Approved,
  AccessTime as Pending,
  GpsFixed as LocationIcon,
  AlternateEmail as EmailIcon,
  ContactPhone as PhoneIcon,
  DateRange as DateIcon,
  SupervisorAccount as GenderIcon,
  LocationOn as LocationPointerIcon,
  Security as SecurityIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { AuthContext } from "../../contexts/AuthContext";
import Loading from "./Loading";

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function DeleteAdminButton(props: any) {
  const globalContext = useContext(GlobalContext);
  const authContext = useContext(AuthContext);
  const user = props?.data;

  const deleteAdminUser = useMutation(
    [user?.Id],
    async () =>
      axios
        .delete(
          `https://test-adminapi.meetleelou.com/api/auth/user/${user?.Id}`,
          {
            headers: { Authorization: `Bearer ${authContext?.auth?.token}` },
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        }),
    {
      onSuccess: (response: any) => {
        props.callback1(false);
        props.parentComp();
        globalContext?.setDisplayQuickAlert({
          open: true,
          message: stringData.info.deleteAdminSuccess,
        });
      },
      onError: () => {
        globalContext?.setDisplayQuickAlert({
          open: true,
          message: stringData.info.deleteAdminError,
        });
      },
    }
  );

  return (
    <Button
      variant="outlined"
      color="warning"
      size="small"
      onClick={() =>
        globalContext?.setDisplayDialog({
          open: true,
          title: "Delete User",
          description: "Are you sure you want to delete this user?",
          buttons: [{ name: "Yes", onClick: () => deleteAdminUser.mutate() }],
        })
      }
      disabled={deleteAdminUser?.isLoading}
    >
      {deleteAdminUser?.isLoading ? (
        <Loading />
      ) : (
        <DeleteIcon color="warning" />
      )}
    </Button>
  );
}

export default DeleteAdminButton;
