import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { grey, orange } from "@material-ui/core/colors";
import { Helmet } from "react-helmet-async";
import { spacing, width } from "@material-ui/system";
import { darken } from "polished";
import { tableCellClasses } from "@material-ui/core/TableCell";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import UserNetworkTabs from "../users/UserNetworkTabs";
import Actions from "../users/Actions";
import AlertDialog from "../users/AlertDialog";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";
import {
  Visibility,
  CheckCircle as CheckIcon,
  Warning as WarningIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, GridSelectionModel} from '@mui/x-data-grid';
import NotificationOptionsDialog from "./NotificationOptionDialog";
import FilterDialog from "./FilterDialog";
import {
  CircularProgress,
  Avatar,
  Button,
  CardContent,
  Chip,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  Container,
  Typography,
  Fab as MuiFab,
  IconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import UsersList from "../dashboardusers/UsersList";
import Loader from "../../components/Loader";
import "./DataTable.css";
const StyledTableCell = styled(TableCell)({
  padding: "5px",
});
const FixedWidthTableCell = styled(StyledTableCell)({
  width: "50%",
});
export type User = {};

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;


const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const ACard = styled(MuiCard)(spacing);
const Card = styled(ACard)`
  cursor: pointer;
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;


const SearchField = (props: any) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Input
        placeholder={stringData.inputPlaceholder.leelouUserSearch}
        onChange={props.oc}
        value={props.value}
        // style={{backgroundColor:"lightgrey"}}
      />
    </Search>
  );
};

function NotificationCenter(props: any) {
  interface User {
    Avatar: String;
    Country: String;
    CreatedDate: String;
    DeviceType: String;
    DisplayName: String;
    Email: String;
    FirstName: String;
    LastName: String;
    LastOnline: String;
    LastUpdate: String;
    Latitude: String;
    Longitude: String;
    MobileCountryCode: String;
    MobileNumber: String;
    "FriendsCount": Number,
    "GuardiansCount": Number,
    "DependantsCount": Number,
    "HasActiveSOS":Boolean;
    "HasPendingInvites": Boolean;
    "IsLoggedOut": Boolean;
    EmailVerifiedStatus:Boolean;
    UserId: 21;
  }
  const columns: GridColDef[] = [
    { 
      field: 'UserId', 
      headerName: 'Id', 
      width: 100,       disableColumnMenu:false,

    },
    {
      field: 'Avatar',
      headerName: 'Avatar',
      width: 100,
      sortable: false,
      disableColumnMenu:true,
      renderCell: (params: GridRenderCellParams) => (
      <Avatar
        alt={String(params.row.FirstName)}
        src={
          "https://leelouasset.s3.ap-southeast-2.amazonaws.com/" +
          String(params.row.Avatar)
        }
      />
      ),
    }, 

  { field: 'FirstName', headerName: 'First Name', width: 130, disableColumnMenu:true, 
  },
  { field: 'LastName', headerName: 'Last Name', width: 130, disableColumnMenu:true, 
  },
  { field: 'MobileCountryCode', headerName: 'Code', width: 100, disableColumnMenu:true, 
  },
  { field: 'MobileNumber', headerName: 'Mobile', width: 130, disableColumnMenu:true, 
  },
    { field: 'Email', headerName: 'Email', width: 250,      disableColumnMenu:true,
  },

  {
    field: 'EmailVerifiedStatus',
    headerName: 'Verified',
    width: 120,
    disableColumnMenu:true,
    renderCell: (params: GridRenderCellParams) => {
      return(params?.row?.EmailVerifiedStatus?<CheckIcon color="success" sx={{height:20, width:20}}/>:<WarningIcon color="warning" />)
    }
    },
  { field: 'DeviceType', headerName: 'Device', width: 150, disableColumnMenu:true, 
},
{ field: 'CreatedDate', headerName: 'Registered', width: 200, disableColumnMenu:true, 
},
{ field: 'HasActiveSOS', headerName: 'SOS Active ', width: 130, disableColumnMenu:true, 
},
{ field: 'FriendsCount', headerName: 'Friends', width: 130, disableColumnMenu:true, },
{ field: 'GuardiansCount', headerName: 'Guardians', width: 130, disableColumnMenu:true, 
},
{ field: 'DependantsCount', headerName: 'Dependants', width: 140, disableColumnMenu:true, 
},
{ field: 'HasPendingInvites', headerName: 'Pending Invites', width: 130, disableColumnMenu:true, 
},
{ field: 'LastOnline', headerName: 'Last Online', width: 130, disableColumnMenu:true, 
},
{ field: 'IsLoggedOut', headerName: 'Status', width: 130, disableColumnMenu:true, 
},
{
  field: 'IsLoggedOut',
  headerName: 'Status',
  width: 120,
  disableColumnMenu:true,
  renderCell: (params: GridRenderCellParams) =>  <Chip
  size="small"
  label={params?.row.IsLoggedOut?"Logged Out":"Logged In"}
  color={params?.row.IsLoggedOut?"error":"success"}
/>},


    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   width: 100,
    //   sortable: false,
    //   disableColumnMenu:true,
    //   renderCell: (params: GridRenderCellParams) => (
    //     <IconButton size="large" color="warning"
    //     onClick={() => viewUserDetail(params?.row)}
    //     >
    //       <Visibility/>
    //     </IconButton>
    //   ),
    // },
  ];


  const navigate = useNavigate();

  const userList: JSX.Element[] = [];
  const [Users, setUsers] = useState([]);
  const [DefaultUsers, setDefaultUsers] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    displayUsers();
  }, []);

  const displayUsers = () => {
    const timeStamp = Math.floor(Date.now() / 1000);
    console.log(timeStamp);
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Dashboard/recent-users/?pagesize=${1000}&pageNo=${page}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setLoading(false);
            console.log(res.data)
            setUsers(res.data);
            setDefaultUsers(res.data);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };
  const [Sos, setSos] = useState([]);

  const GetSOS = async () => {
    const timeStamp = Math.floor(Date.now() / 1000);

    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        await axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Sos/list?timestamp=${timeStamp}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setSos(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };
  const [ViewDetail, setViewDetail] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [UserDetail, setUserDetail] = useState({} as User);
  const [SearchTerm, setSearchTerm] = useState("");

  const handleChange = async (event: any) => {
    const term = event.target.value;
    if (term) {
      const results = DefaultUsers.filter((user: User) =>
        user?.FirstName.toLowerCase().includes(term.toLowerCase())
      );
      setUsers(results);
    } else {
      setUsers(DefaultUsers);
    }
    setSearchTerm(term);
  };

  const [UserNetwork, setUserNetwork] = useState([]);

  const viewUserDetail = (key: any) => {
    setUserDetail(key);
    setViewDetail(true);
  };




  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
  const getRowClassName = (params:any) => {
    return params.id === selectedRow ? 'selected-row' : '';
  };
  const handleSelectionChange = (newSelectionModel: GridSelectionModel) => {
    setSelectedRows(newSelectionModel);
  };

  // const getSelectedEmails = () => {
  //   return selectedRows.map((id) => {
  //     const row= Users.find((user:User) => user.UserId === id);
  //     return row ? row.Email : null;
  //   });
  // };



  return (
    <React.Fragment>
      <Helmet title="Notification Center" />
      {/* <BigAvatar
                  alt={String(key?.FirstName)}
                  src={
                    "http://meetleeloudashboard.com.s3-website-ap-southeast-2.amazonaws.com/" +
                    String(key?.Avatar)
                  }
                /> */}
      <Grid container style={{ height:"100%"}} spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          style={{ backgroundColor:"#fff"}} 
        >
          <Grid container spacing={2} px={2} style={{height:'5%'}}>
            <Grid item xs={8}>
              <SearchField oc={handleChange} value={SearchTerm} />
            </Grid>
            <Grid item xs={2}>
              <FilterDialog data={DefaultUsers} callback={setUsers} />
            </Grid>
            <Grid item xs={2} >
              <NotificationOptionsDialog data={DefaultUsers} callback={setUsers} selectedRows={selectedRows}/>
            </Grid>
          </Grid>
 
          <Grid container spacing={0} px={2} style={{height:'95%'}}>
          <DataGrid
        rows={Users}
        columns={columns}
        loading={Loading}
        getRowId={(row) => row.UserId}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={handleSelectionChange}
        onRowClick={(data:any) => {viewUserDetail(data?.row);setSelectedRow(data?.id)}}
        getRowClassName={getRowClassName}

      />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          sx={{
            flexDirection: "row",
            overflowY: "auto", // Enable vertical scrolling
            height: "100%", // Take full height of the parent
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            {ViewDetail ? (
              <UserNetworkTabs data={UserDetail} SoS={Sos} />
            ) : (
              <Card sx={{ width: "100%" }} pt={0}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                    p={5}
                  >
                    <CardContent>
                      <Typography variant="h5" color={grey[500]}>
                        {stringData.info.userViewDetail}
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NotificationCenter;
