import * as React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

type languageOptionsType = {
  [key: string]: {
    icon: string;
    name: string;
    code:string;
  };
};

const languageOptions: languageOptionsType = {
  en: {
    icon: "/static/img/flags/us.png",
    name: "English",
    code: "en",
  },
  ja: {
    icon: "/static/img/flags/jp.png",
    name: "Japanese",
    code: "ja",
  },
  zh: {
    icon: "/static/img/flags/cn.png",
    name: "Chinese",
    code: "zh",
  },
  el: {
    icon: "/static/img/flags/gr.png",
    name: "Greek",
    code: "el",
  },
  hi: {
    icon: "/static/img/flags/in.png",
    name: "Hindi",
    code: "hi",
  },

};

function NavbarLanguagesDropdown() {
  const { i18n } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const leelou_lang = localStorage.getItem("leelou_dashboard_language");

  if(!leelou_lang){
    localStorage.setItem("leelou_dashboard_language", "en");

  }

  const [selectedLanguage,setSelectedLanguage] = React.useState(leelou_lang?languageOptions[leelou_lang]:languageOptions.en);

  
  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const [lang, setLang] = React.useState<any>("en");

  const handleLanguageChange = (language: string) => {
    setLang(languageOptions[language].code);
    setSelectedLanguage(languageOptions[language]);
    localStorage.setItem("leelou_dashboard_language", languageOptions[language].code);
    closeMenu();
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Tooltip title="Languages">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Flag src={selectedLanguage.icon} alt={selectedLanguage.name} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;
