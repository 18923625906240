import React, { createContext, useState, useEffect, ReactNode } from "react";
import { SosType } from "../pages/sos/SosTypes";

interface ActionButtonProps {
  name: string;
  onClick: () => void;
}

interface DialogProps {
  open: boolean;
  title: string;
  description: string;
  buttons: ActionButtonProps[];
}

interface QuickAlertProps {
  message: string;
  open: boolean;
}

type AuthContextType = {
  selectedSos: SosType;
  displayDialog: DialogProps;
  setDisplayDialog: React.Dispatch<React.SetStateAction<DialogProps>>;
  displayQuickAlert: QuickAlertProps;
  setDisplayQuickAlert: React.Dispatch<React.SetStateAction<QuickAlertProps>>;
};

const GlobalContext = createContext<AuthContextType | null>(null);

const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [selectedSos, setSelectedSos] = useState<SosType>({
    Id: 0,
    Guid: "",
    CreateDate: "",
    LastUpdate: "",
    ConversationId: "",
    AudioId: "",
    LocationEnabled: 1,
    MicEnabled: 1,
    NotificationsEnabled: 1,
    EndDate: "",
    EndRequest: "",
    EndReason: "",
    Status: "",
    VictimUserDetail: {
      User: {
        UserId: 0,
        DisplayName: "",
      },
      Activity: {
        LastActive: "",
        Battery: 0,
        Latitude: 0,
        Longitude: 0,
        Accuracy: 0,
      },
      Contact: {
        Country: "",
        Number: "",
        Dialable: "",
      },
    },
    GuardianDetails: {
      User: {
        UserId: 1,
        DisplayName: "",
        Email: "",
        Birthday: "",
        Address: "",
        Suburb: "",
        Postcode: "",
        State: "",
        Country: "",
        ShareLocation: "",
        Gender: "",
        IsOffline: true,
      },
      Activity: {
        LastUpdate: "",
        LastOnline: "",
        Battery: 0,
        Latitude: 0,
        Longitude: 0,
        Accuracy: 0,
        ZoneName: "",
      },
      Contact: {
        MobileId: 0,
        GuardianMobileCountry: "",
        Number: "",
        Dialable: "",
      },
    },
  });

  const [displayDialog, setDisplayDialog] = useState<DialogProps>({
    open: false,
    title: "",
    description: "",
    buttons: [],
  });

  const [displayQuickAlert, setDisplayQuickAlert] = useState<QuickAlertProps>({
    message: "",
    open: false,
  });
  return (
    <GlobalContext.Provider
      value={{
        selectedSos,
        displayDialog,
        setDisplayDialog,
        displayQuickAlert,
        setDisplayQuickAlert,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
