import React,{useState, useEffect} from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { rgba } from "polished";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import axios from "axios";
const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

const BarChart = ({ theme }: ThemeProps<Theme>) => {
  const secondDatasetColor = rgba(theme.palette.success.light, 0.7);

  useEffect(() => {
    fetchRegisteredUserCount();
  }, []);

  const[data, setData]= useState({} as any);
  const fetchRegisteredUserCount = async () => {
    try {
      const response = await axios.get(
        "https://test-adminapi.meetleelou.com/api/Dashboard/user-count-date",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if(response?.data?.Status){
        const data = response?.data?.Data;
        const dateRangeData = generateDateRangeData( data);
        const chartData = {
          labels: dateRangeData?.map((item:any) => item.Date),
          datasets: [
      {type: "line",
              label: "User Registration",
              backgroundColor: secondDatasetColor,
              borderColor: secondDatasetColor,
              hoverBackgroundColor: secondDatasetColor,
              hoverBorderColor: secondDatasetColor,
              data:  dateRangeData?.map((item:any) => item.Count),
              barPercentage: 0.5,
              categoryPercentage: 0.5,
              borderRadius: 6,
              options: {
                interaction: {
                  intersect: false,
                  mode: 'index',
                },
              }
            }
          ],
        };
        setData(chartData);
      }
    

    } catch (error) {
      console.log(error);
    }
  };

  const generateDateRangeData = ( data:any) => {
    const startDate = new Date('2024-01-01');
    const endDate = new Date();
    const dateRangeData = [];
    const dateMap = new Map(data.map((item:any)=> [new Date(item.Date).toISOString().split('T')[0], item.Count]));
  
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const formattedDate = date.toISOString().split('T')[0];
      dateRangeData.push({
        Date: formattedDate,
        Count: dateMap.get(formattedDate) || 0
      });
    }
  
    return dateRangeData;
  };
  


  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        align: "center", //start for left align'
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          fontColor: theme.palette.text.secondary,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false // This hides the x-axis labels
        }
      }
    },
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="User Registration"
      />

      <CardContent>
        <ChartWrapper>
          <Chart type="bar" data={data?data:{}} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart);
