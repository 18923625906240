import React from "react";
import { useNavigate } from "react-router-dom";
import SignIn from "../../pages/auth/SignIn";
function SignOut() {
  const navigate = useNavigate();
  localStorage.removeItem("auth_session");
  localStorage.removeItem("leelou_aun");
  navigate("/auth/sign-in");
  return <SignIn />;
}

export default SignOut;
