import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Avatar, Paper, Typography } from "@material-ui/core";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import { grey } from "@material-ui/core/colors";

const BigAvatar = styled(Avatar)`
  width: 100%;
  height: 80px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
  border-radius: 0px;
  object-fit: contain;
`;
const bgStyle = {
  sideImage: {
    width: "100%",
    height: "100vh",
  },
} as const;

function SignIn() {
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <Grid
        container
        justifyContent="center"
        direction={"row"}
        alignItems="stretch"
      >
        <Grid container xs={12} md={7}>
          <img
            src="/static/img/avatars/login-side-image.png"
            style={bgStyle.sideImage}
          />
        </Grid>
        <Grid
          container
          xs={12}
          md={5}
          alignItems="center"
          justifyContent={"center"}
          height="100vh"
        >
          <Grid xs={8} container alignItems="center">
            <Grid item xs={12} container justifyContent="center">
              <img
                height={"80px"}
                width={"90px"}
                alt="Leelou Logo"
                src="/static/img/avatars/leelou-logo.png"
              />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="h2" align="center" width={"100%"}>
                Leelou
              </Typography>
            </Grid>
            <Grid item xs={12} py={10} container justifyContent="center">
              <Typography
                component="h1"
                variant="h6"
                align="center"
                gutterBottom
                color={grey[500]}
              >
                Welcome back! Please login to your account.
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <SignInComponent />
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="flex-end"
              justifyContent="center"
              py={10}
            >
              Terms of use. Privacy Policy.
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SignIn;
