let lang = localStorage.getItem("leelou_dashboard_language");
let selectedLanguage = lang?lang:"en";
    const stringData = {
    language:{
        en:{
            tableHeader:
            {
            name:"Full Name",
            userId:"User Id",
            email:"Email",
            actions:"Actions",
            status:"Status",
            sosId:"Id",
            sosStatus:"SOS Status",
            createdDate:"Date",
            assignedUsers:"Assigned Users",

                },
            sosDetails:{
                startTime:"Start Time",
                lastOnline:"Last Online",
                sharedLocation: "Shared Location",
                sharedVoicemails:"Shared Voicemails",
                pendingCancel: "Pending Cancel",
                guardians:"Guardians",
                chat:"Chat",
                interactions:"Interactions",
            },
            info:{
                userViewDetail:"Select user to view details",
                sosViewDetail:"Select SOS Alerts to view detail",
                deleteRoleMesage:"Are you sure you want to delete this role from roles list? If you delete this role you will lose all people assigned to this role.",
                deleteRoleSuccess: "The role deleted successfully.",
                deleteRoleError: "Error occurred while deleting role.",
                editRoleSuccess: "Role successfully edited.",
                editRoleError: "Error occured while editing role",
                createRoleSuccess: "Role successfully created.",   
                createRoleError: "Error occurred while creating role.",    
                assignRoleFailed: "Error occurred while assignign role to user.",
                assignRoleSuccess:"Role assigned successfully to the user.",
                deleteAdminMessage:"Are you sure you want to delete the user from the administrator list?",
                deleteAdminSuccess: "The user has been successfully removed from admin's list.",
                deleteAdminError:"Error occurred while deleting admin user.",
                suspendAdminMesssage:"Are you sure you want to suspend admin from the administrator list?",
                unsuspendAdminMesssage:"Are you sure you want to un-suspend admin from the administrator list?",
                suspendAdminSuccess:"The user suspended successfully.",
                unsuspendAdminSuccess:"The user un-suspended uscccessfully.",
                message: "Message",
            },
            button:{
                viewMore: "View More",
                close: "Close",
                noMoreData:"No more data",
                viewDetails:"View Details",
                cancel:"Cancel",
                delete:"Delete",
                suspend:"Suspend",
                unSuspend:"Un Suspend",
            },
            filter:{
                filter:"Filter",
                all:"All",
                active:"Active",
                pendingCancel:"Pending Cancel",
                cancelled:"Cancelled",
                falseStart:"False Start",
            },
            dUsers:{
                addNewAdmin:"Add New Admin",
                roleConfiguration:"Role Configuration",
                createNewRole:"Create New Role",
                currentRoles:"Current Roles",
                actions:"Actions",
                viewRole:"View Role",
                editRole:"Edit Role",
                nameOfRole:"Name of the role",
                editRole: "Edit Role",
                status:"Status",
                addRole: "Add Role",
                suspended: "Suspended",
                role:"Role",
                all:"All",
                active: "Active",
                securitySuits:"Security Suits",
                adminRoles: "Admin Roles",
                addPermissions: "Add Permissions",
                address: "Address",
                permissions: "Permisison",
                email:"Email",
                phone:"Phone",
                country:"Country",
                username: "Username",
                userId: "User Id",
                view:"View",
                createdDate:"Created Date",
                assignedUsers:"Assigned Users",
                
            },
            lUsers:{
                sosHistory:"SOS History",
                userId:"User Id",
                dob:"Date of Birth",
                gender:"Gender",
                registeredDate:"Registered Date",
                country:"Location Sharing",
                email: "Email",
                phone:"Phone",
                lastLogin:"Last Login",
                guardians: "Guardians",
                friends: "Friends",
                dependents: "Dependents",
                pending: "Pending",
                approved: "Approved",
                address: "Address",

            },
            inputPlaceholder:{
                leelouUserSearch: "Search user by name",
                dashboardUserSearch: "Search user by name",
                sosSearch: "Search SOS by name",
                feedbacksSearch: "Search feedbacks",

            },
            login:{
                welcome:"Welcome back! Please login to your account.",
                username: "Username",
                password: "Password",
                rememberme: "Remember me",
                forgotPassword: "Forgot Password",
                signIn: "Sign In",
                signUp: "Sign Up",
                incorrectCredentials: "Username or password incorrect",
                usernameRequired: "Username is required",
                passwordRequired: "Password is required"
            },
            dashboard:{
                registeredUsers:"Total Registered Users",
                iosdownloads: "IOS Downloads",
                androiddownloads: "Android Downloads",
                sosAlertData: "SOS Alert Data",
                downloads: "Downloads",
                successfulSOS: "Successful SOS",
                sosByCountry: "SOS by Country",
                totalLeelouCall:"Total Leelou Call",
                policeCall: "Police Call",
                viewFullReport: "View Full Report",
                total: "Total",
            }

        },
        ja:{
            
                "tableHeader": {
                    "name": "フルネーム",
                    "userId": "ユーザーID",
                    "email": "Eメール",
                    "actions": "行動",
                    "status": "状態",
                    "sosId": "sso id",
                    "sosStatus": "SOSステータス",
                    "createdDate": "作成日",
                    "assignedUsers": "割り当てられたユーザー"
                },
                "sosDetails": {
                    "startTime": "始まる時間",
                    "lastOnline": "ラストオンライン",
                    "sharedLocation": "共有場所",
                    "sharedVoicemails": "共有ボイスメール",
                    "pendingCancel": "保留中のキャンセル",
                    "guardians": "保護者",
                    "chat": "チャット",
                    "interactions": "相互作用"
                },
                "info": {
                    "userViewDetail": "ユーザーを選択して詳細を表示します",
                    "sosViewDetail": "SOSアラートを選択して詳細を表示します",
                    "deleteRoleMesage": "この役割を役割リストから削除したいですか？この役割を削除すると、この役割に割り当てられたすべての人が失われます。",
                    "deleteRoleSuccess": "役割は正常に削除されました。",
                    "deleteRoleError": "役割の削除中にエラーが発生しました。",
                    "editRoleSuccess": "役割は正常に編集されました。",
                    "editRoleError": "編集中にエラーが発生しました",
                    "createRoleSuccess": "役割は正常に作成されました。",
                    "createRoleError": "役割の作成中にエラーが発生しました。",
                    "assignRoleFailed": "エラーは、ユーザーへの譲渡ロール中に発生しました。",
                    "assignRoleSuccess": "ユーザーに正常に割り当てられた役割。",
                    "deleteAdminMessage": "管理者リストからユーザーを削除したいですか？",
                    "deleteAdminSuccess": "ユーザーは、管理者のリストから正常に削除されました。",
                    "deleteAdminError": "管理者ユーザーの削除中にエラーが発生しました。",
                    "suspendAdminMesssage": "管理者リストから管理者を一時停止したいですか？",
                    "unsuspendAdminMesssage": "管理者リストから管理者の懸濁を解除したいですか？",
                    "suspendAdminSuccess": "ユーザーは正常に停止しました。",
                    "unsuspendAdminSuccess": "ユーザーは正常にサスピーされます。"
                },
                "button": {
                    "viewMore": "もっと見る",
                    "close": "近い",
                    "noMoreData": "これ以上のデータはありません",
                    "viewDetails": "詳細を見る",
                    "cancel": "キャンセル",
                    "delete": "消去"
                },
                "filter": {
                    "filter": "フィルター",
                    "all": "全て",
                    "active": "アクティブ",
                    "pendingCancel": "保留中のキャンセル",
                    "cancelled": "キャンセル",
                    "falseStart": "false Start"
                },
                "dUsers": {
                    "addNewAdmin": "新しい管理者を追加します",
                    "roleConfiguration": "ロール構成",
                    "createNewRole": "新しい役割を作成します",
                    "currentRoles": "現在の役割",
                    "actions": "行動",
                    "viewRole": "役割を表示します",
                    "editRole": "役割を編集します",
                    "nameOfRole": "役割の名前",
                    "status": "状態",
                    "addRole": "役割を追加します",
                    "suspended": "一時停止",
                    "role": "役割",
                    "all": "全て",
                    "active": "アクティブ",
                    "securitySuits": "セキュリティスーツ",
                    "adminRoles": "管理者の役割",
                    "addPermissions": "権限を追加します",
                    "address": "住所",
                    "permissions": "許可",
                    "email": "Eメール",
                    "phone": "電話",
                    "country": "国",
                    "username": "ユーザー名",
                    "userId": "ユーザーID",
                    "view": "意見",
                    "createdDate": "作成日",
                    "assignedUsers": "割り当てられたユーザー"
                },
                "lUsers": {
                    "sosHistory": "SOSの歴史",
                    "userId": "ユーザーID",
                    "dob": "生年月日",
                    "gender": "性別",
                    "registeredDate": "登録日",
                    "country": "カウンター",
                    "email": "Eメール",
                    "phone": "電話",
                    "lastLogin": "前回のログイン",
                    "guardians": "保護者",
                    "friends": "友達",
                    "dependents": "扶養家族",
                    "pending": "保留中",
                    "approved": "承認済み",
                    "address": "住所"
                },
                "inputPlaceholder": {
                    "leelouUserSearch": "名前でユーザーを検索します",
                    "dashboardUserSearch": "名前でユーザーを検索します",
                    "sosSearch": "名前でSOSを検索します",
                    "feedbacksSearch": "フィードバックを検索します"
                },
                "login": {
                    "welcome": "お帰りなさい！アカウントにログインしてください。",
                    "username": "ユーザー名",
                    "password": "パスワード",
                    "rememberme": "私を覚えてますか",
                    "forgotPassword": "パスワードをお忘れですか",
                    "signIn": "ログイン",
                    "signUp": "サインアップ",
                    "incorrectCredentials": "ユーザー名またはパスワードが間違っています",
                    "usernameRequired": "ユーザー名が必要です",
                    "passwordRequired": "パスワードが必要です"
                }
            

        },
        hi:{
            "tableHeader": {
                "name": "पूरा नाम",
                "userId": "यूज़र आईडी",
                "email": "ईमेल",
                "actions": "कार्रवाई",
                "status": "दर्जा",
                "sosId": "एसएसओ आईडी",
                "sosStatus": "एसओएस स्थिति",
                "createdDate": "सृजित दिनांक",
                "assignedUsers": "असाइन किए गए उपयोगकर्ता"
            },
            "sosDetails": {
                "startTime": "समय शुरू",
                "lastOnline": "अंतिम ऑनलाइन",
                "sharedLocation": "साझा स्थान",
                "sharedVoicemails": "साझा ध्वनि मेल",
                "pendingCancel": "लंबित रद्द",
                "guardians": "रखवालों",
                "chat": "बात करना",
                "interactions": "बातचीत"
            },
            "info": {
                "userViewDetail": "विवरण देखने के लिए उपयोगकर्ता का चयन करें",
                "sosViewDetail": "विस्तार देखने के लिए SOS अलर्ट का चयन करें",
                "deleteRoleMesage": "क्या आप सुनिश्चित हैं कि आप इस भूमिका को भूमिकाओं की सूची से हटाना चाहते हैं?यदि आप इस भूमिका को हटा देते हैं तो आप इस भूमिका को सौंपे गए सभी लोगों को खो देंगे।",
                "deleteRoleSuccess": "भूमिका सफलतापूर्वक हटा दी गई।",
                "deleteRoleError": "भूमिका हटाते समय त्रुटि हुई।",
                "editRoleSuccess": "भूमिका सफलतापूर्वक संपादित।",
                "editRoleError": "संपादन करते समय त्रुटि हुई",
                "createRoleSuccess": "भूमिका सफलतापूर्वक बनाई गई।",
                "createRoleError": "भूमिका बनाते समय त्रुटि हुई।",
                "assignRoleFailed": "उपयोगकर्ता के लिए असाइनमेंट भूमिका के दौरान त्रुटि हुई।",
                "assignRoleSuccess": "उपयोगकर्ता को सफलतापूर्वक भूमिका सौंपी गई।",
                "deleteAdminMessage": "क्या आप सुनिश्चित हैं कि आप उपयोगकर्ता को व्यवस्थापक सूची से हटाना चाहते हैं?",
                "deleteAdminSuccess": "उपयोगकर्ता को सफलतापूर्वक व्यवस्थापक की सूची से हटा दिया गया है।",
                "deleteAdminError": "व्यवस्थापक उपयोगकर्ता को हटाते समय त्रुटि हुई।",
                "suspendAdminMesssage": "क्या आप सुनिश्चित हैं कि आप व्यवस्थापक सूची से व्यवस्थापक को निलंबित करना चाहते हैं?",
                "unsuspendAdminMesssage": "क्या आप सुनिश्चित हैं कि आप व्यवस्थापक सूची से अन-ससेंपेंड व्यवस्थापक चाहते हैं?",
                "suspendAdminSuccess": "उपयोगकर्ता को सफलतापूर्वक निलंबित कर दिया गया।",
                "unsuspendAdminSuccess": "उपयोगकर्ता सफलतापूर्वक निलंबित है।"
            },
            "button": {
                "viewMore": "और देखें",
                "close": "बंद करना",
                "noMoreData": "अधिक डेटा नहीं",
                "viewDetails": "विवरण देखें",
                "cancel": "रद्द करना",
                "delete": "मिटाना"
            },
            "filter": {
                "filter": "फ़िल्टर",
                "all": "सभी",
                "active": "सक्रिय",
                "pendingCancel": "लंबित रद्द",
                "cancelled": "रद्द",
                "falseStart": "ग़लत शुरुआत"
            },
            "dUsers": {
                "addNewAdmin": "नया व्यवस्थापक जोड़ें",
                "roleConfiguration": "भूमिका विन्यास",
                "createNewRole": "नई भूमिका बनाएं",
                "currentRoles": "वर्तमान भूमिकाएँ",
                "actions": "कार्रवाई",
                "viewRole": "दृश्य -भूमिका",
                "editRole": "भूमिका संपादित करें",
                "nameOfRole": "भूमिका का नाम",
                "status": "दर्जा",
                "addRole": "भूमिका जोड़ें",
                "suspended": "निलंबित",
                "role": "भूमिका",
                "all": "सभी",
                "active": "सक्रिय",
                "securitySuits": "सुरक्षा सूट",
                "adminRoles": "व्यवस्थापक भूमिकाएँ",
                "addPermissions": "अनुमतियाँ जोड़ें",
                "address": "पता",
                "permissions": "अनुमति",
                "email": "ईमेल",
                "phone": "फ़ोन",
                "country": "देश",
                "username": "उपयोगकर्ता नाम",
                "userId": "यूज़र आईडी",
                "view": "राय",
                "createdDate": "सृजित दिनांक",
                "assignedUsers": "असाइन किए गए उपयोगकर्ता"
            },
            "lUsers": {
                "sosHistory": "एसओएस हिस्ट्री",
                "userId": "यूज़र आईडी",
                "dob": "जन्म की तारीख",
                "gender": "लिंग",
                "registeredDate": "पंजीकृत तारीख",
                "country": "विरोध करना",
                "email": "ईमेल",
                "phone": "फ़ोन",
                "lastLogin": "आखरी लॉगइन",
                "guardians": "रखवालों",
                "friends": "मित्र",
                "dependents": "आश्रितों",
                "pending": "लंबित",
                "approved": "स्वीकृत",
                "address": "पता"
            },
            "inputPlaceholder": {
                "leelouUserSearch": "नाम से उपयोगकर्ता खोजें",
                "dashboardUserSearch": "नाम से उपयोगकर्ता खोजें",
                "sosSearch": "नाम से एसओएस खोजें",
                "feedbacksSearch": "खोज प्रतिक्रियाएँ"
            },
            "login": {
                "welcome": "वापसी पर स्वागत है!कृपया अपने खाते लॉग इन करें।",
                "username": "उपयोगकर्ता नाम",
                "password": "पासवर्ड",
                "rememberme": "पहचाना की नहीं",
                "forgotPassword": "पासवर्ड भूल गए",
                "signIn": "साइन इन करें",
                "signUp": "साइन अप करें",
                "incorrectCredentials": "उपयोगकर्ता नाम या पासवर्ड गलत है",
                "usernameRequired": "उपयोगकर्ता नाम आवश्यक है",
                "passwordRequired": "पासवर्ड की आवश्यकता है"
            }
        },
        zh:{
            "tableHeader": {
                "name": "全名",
                "userId": "用户身份",
                "email": "电子邮件",
                "actions": "动作",
                "status": "地位",
                "sosId": "SSO ID",
                "sosStatus": "SOS状态",
                "createdDate": "创建日期",
                "assignedUsers": "分配的用户"
            },
            "sosDetails": {
                "startTime": "开始时间",
                "lastOnline": "最后在线",
                "sharedLocation": "共享位置",
                "sharedVoicemails": "共享语音邮件",
                "pendingCancel": "待定取消",
                "guardians": "监护人",
                "chat": "聊天",
                "interactions": "互动"
            },
            "info": {
                "userViewDetail": "选择用户查看详细信息",
                "sosViewDetail": "选择SOS警报以查看详细信息",
                "deleteRoleMesage": "您确定要从角色列表中删除此角色吗？如果删除此角色，您将失去所有分配给此角色的人。",
                "deleteRoleSuccess": "该角色成功地删除了。",
                "deleteRoleError": "删除角色时发生错误。",
                "editRoleSuccess": "角色成功编辑。",
                "editRoleError": "编辑角色时发生错误",
                "createRoleSuccess": "角色成功创造了。",
                "createRoleError": "创建角色时发生错误。",
                "assignRoleFailed": "在向用户分配角色时发生错误。",
                "assignRoleSuccess": "角色成功分配给用户。",
                "deleteAdminMessage": "您确定要从管理员列表中删除用户吗？",
                "deleteAdminSuccess": "用户已成功从管理员列表中删除。",
                "deleteAdminError": "删除管理用户时发生错误。",
                "suspendAdminMesssage": "您确定要从管理员列表中暂停管理员吗？",
                "unsuspendAdminMesssage": "您确定要从管理员列表中解开uspend管理员吗？",
                "suspendAdminSuccess": "用户成功暂停。",
                "unsuspendAdminSuccess": "用户成功地悬浮。"
            },
            "button": {
                "viewMore": "查看更多",
                "close": "关",
                "noMoreData": "没有更多数据",
                "viewDetails": "查看详情",
                "cancel": "取消",
                "delete": "删除"
            },
            "filter": {
                "filter": "筛选",
                "all": "全部",
                "active": "积极的",
                "pendingCancel": "待定取消",
                "cancelled": "取消",
                "falseStart": "错误的开始"
            },
            "dUsers": {
                "addNewAdmin": "添加新管理员",
                "roleConfiguration": "角色配置",
                "createNewRole": "创建新角色",
                "currentRoles": "当前角色",
                "actions": "动作",
                "viewRole": "查看角色",
                "editRole": "编辑角色",
                "nameOfRole": "角色名称",
                "status": "地位",
                "addRole": "添加角色",
                "suspended": "暂停",
                "role": "角色",
                "all": "全部",
                "active": "积极的",
                "securitySuits": "安全诉讼",
                "adminRoles": "管理角色",
                "addPermissions": "添加权限",
                "address": "地址",
                "permissions": "允许",
                "email": "电子邮件",
                "phone": "电话",
                "country": "国家",
                "username": "用户名",
                "userId": "用户身份",
                "view": "看法",
                "createdDate": "创建日期",
                "assignedUsers": "分配的用户"
            },
            "lUsers": {
                "sosHistory": "SOS历史",
                "userId": "用户身份",
                "dob": "出生日期",
                "gender": "性别",
                "registeredDate": "注册日期",
                "country": "柜台",
                "email": "电子邮件",
                "phone": "电话",
                "lastLogin": "上次登录",
                "guardians": "监护人",
                "friends": "朋友们",
                "dependents": "家属",
                "pending": "待办的",
                "approved": "得到正式认可的",
                "address": "地址"
            },
            "inputPlaceholder": {
                "leelouUserSearch": "按名称搜索用户",
                "dashboardUserSearch": "按名称搜索用户",
                "sosSearch": "按名称搜索SOS",
                "feedbacksSearch": "搜索反馈"
            },
            "login": {
                "welcome": "欢迎回来！请登录到您的帐户。",
                "username": "用户名",
                "password": "密码",
                "rememberme": "记得我",
                "forgotPassword": "忘记密码",
                "signIn": "登入",
                "signUp": "报名",
                "incorrectCredentials": "用户名或密码不正确",
                "usernameRequired": "需要用户名",
                "passwordRequired": "密码是必需的"
            }
        },
        el:{
            "tableHeader": {
                "name": "Πλήρες όνομα",
                "userId": "Ταυτότητα χρήστη",
                "email": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
                "actions": "Ενέργειες",
                "status": "Κατάσταση",
                "sosId": "SSO ID",
                "sosStatus": "Κατάσταση SOS",
                "createdDate": "Δημιουργημένη ημερομηνία",
                "assignedUsers": "Εκχωρημένοι χρήστες"
            },
            "sosDetails": {
                "startTime": "Ωρα έναρξης",
                "lastOnline": "Τελευταίο online",
                "sharedLocation": "Κοινόχρηστη τοποθεσία",
                "sharedVoicemails": "Κοινόχρηστα φωνητικά μηνύματα",
                "pendingCancel": "Εκκρεμεί ακύρωση",
                "guardians": "Κηδεμόνες",
                "chat": "Κουβέντα",
                "interactions": "Αλληλεπιδράσεις"
            },
            "info": {
                "userViewDetail": "Επιλέξτε χρήστη για προβολή λεπτομερειών",
                "sosViewDetail": "Επιλέξτε SOS ειδοποιήσεις για να δείτε λεπτομέρειες",
                "deleteRoleMesage": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον ρόλο από τη λίστα ρόλων;Εάν διαγράψετε αυτόν τον ρόλο, θα χάσετε όλους τους ανθρώπους που έχουν ανατεθεί σε αυτό το ρόλο.",
                "deleteRoleSuccess": "Ο ρόλος διαγράφηκε με επιτυχία.",
                "deleteRoleError": "Παρουσιάστηκε σφάλμα κατά τη διαγραφή του ρόλου.",
                "editRoleSuccess": "Ο ρόλος επεξεργάστηκε με επιτυχία.",
                "editRoleError": "Παρουσιάστηκε σφάλμα κατά την επεξεργασία του ρόλου",
                "createRoleSuccess": "Ο ρόλος δημιουργήθηκε με επιτυχία.",
                "createRoleError": "Παρουσιάστηκε σφάλμα κατά τη δημιουργία ρόλου.",
                "assignRoleFailed": "Παρουσιάστηκε σφάλμα κατά την εκχώρηση του ρόλου του χρήστη.",
                "assignRoleSuccess": "Ο ρόλος που έχει ανατεθεί με επιτυχία στον χρήστη.",
                "deleteAdminMessage": "Είστε βέβαιοι ότι θέλετε να διαγράψετε τον χρήστη από τη λίστα διαχειριστή;",
                "deleteAdminSuccess": "Ο χρήστης έχει αφαιρεθεί με επιτυχία από τη λίστα του διαχειριστή.",
                "deleteAdminError": "Παρουσιάστηκε σφάλμα κατά τη διαγραφή του χρήστη διαχειριστή.",
                "suspendAdminMesssage": "Είστε βέβαιοι ότι θέλετε να αναστείλετε τον διαχειριστή από τη λίστα διαχειριστή;",
                "unsuspendAdminMesssage": "Είστε βέβαιοι ότι θέλετε να προσβάλλετε τον διαχειριστή από τη λίστα διαχειριστή;",
                "suspendAdminSuccess": "Ο χρήστης αναστέλλεται με επιτυχία.",
                "unsuspendAdminSuccess": "Ο χρήστης είναι επιτυχώς επιτυχώς."
            },
            "button": {
                "viewMore": "Δείτε περισσότερα",
                "close": "Κλείσε",
                "noMoreData": "Δεν υπάρχουν άλλα δεδομένα",
                "viewDetails": "Δείτε λεπτομέρειες",
                "cancel": "Ματαίωση",
                "delete": "Διαγράφω"
            },
            "filter": {
                "filter": "Φίλτρο",
                "all": "Ολα",
                "active": "Ενεργός",
                "pendingCancel": "Εκκρεμεί ακύρωση",
                "cancelled": "Ακυρώθηκε",
                "falseStart": "Ψεύτικο ξεκίνημα"
            },
            "dUsers": {
                "addNewAdmin": "Προσθήκη νέου διαχειριστή",
                "roleConfiguration": "Διαμόρφωση ρόλου",
                "createNewRole": "Δημιουργήστε νέο ρόλο",
                "currentRoles": "Τρέχοντες ρόλοι",
                "actions": "Ενέργειες",
                "viewRole": "Προβολή ρόλου",
                "editRole": "Επεξεργασία ρόλου",
                "nameOfRole": "Όνομα του ρόλου",
                "status": "Κατάσταση",
                "addRole": "Προσθέστε ρόλο",
                "suspended": "Ανασταλεί",
                "role": "Ρόλος",
                "all": "Ολα",
                "active": "Ενεργός",
                "securitySuits": "Κοστούμια ασφαλείας",
                "adminRoles": "Ρόλοι διαχειριστή",
                "addPermissions": "Προσθήκη δικαιωμάτων",
                "address": "Διεύθυνση",
                "permissions": "Αδεια",
                "email": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
                "phone": "Τηλέφωνο",
                "country": "Χώρα",
                "username": "Όνομα χρήστη",
                "userId": "Ταυτότητα χρήστη",
                "view": "Θέα",
                "createdDate": "Δημιουργημένη ημερομηνία",
                "assignedUsers": "Εκχωρημένοι χρήστες"
            },
            "lUsers": {
                "sosHistory": "Ιστορικό SOS",
                "userId": "Ταυτότητα χρήστη",
                "dob": "Ημερομηνια γεννησης",
                "gender": "Γένος",
                "registeredDate": "Ημερομηνία εγγεγραμμένης",
                "country": "Μετρητής",
                "email": "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
                "phone": "Τηλέφωνο",
                "lastLogin": "Τελευταία είσοδος",
                "guardians": "Κηδεμόνες",
                "friends": "Οι φιλοι",
                "dependents": "Εξαρτώμενα άτομα",
                "pending": "εκκρεμής",
                "approved": "Εγκεκριμένος",
                "address": "Διεύθυνση"
            },
            "inputPlaceholder": {
                "leelouUserSearch": "Αναζήτηση χρήστη ανά όνομα",
                "dashboardUserSearch": "Αναζήτηση χρήστη ανά όνομα",
                "sosSearch": "Αναζήτηση SOS με το όνομα",
                "feedbacksSearch": "Ανατροφοδότηση αναζήτησης"
            },
            "login": {
                "welcome": "Καλως ορισες πισω!Συνδεθείτε στον λογαριασμό σας.",
                "username": "Όνομα χρήστη",
                "password": "Κωδικός πρόσβασης",
                "rememberme": "Θυμήσου με",
                "forgotPassword": "Ξεχάσατε τον κωδικό",
                "signIn": "Συνδεθείτε",
                "signUp": "Εγγραφείτε",
                "incorrectCredentials": "Όνομα χρήστη ή κωδικός λανθασμένος",
                "usernameRequired": "Απαιτείται όνομα χρήστη",
                "passwordRequired": "Απαιτείται κωδικός"
            }
        }

    }
}
export default (stringData.language[selectedLanguage]);