import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  TextField as MuiTextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import SignInComponent from "../../components/auth/SignIn";
import { Avatar, Paper, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import axios from "axios";
import { LoadingButton } from "@material-ui/lab";
import { Alert } from "@material-ui/core";
import { CountryList } from "./CountryList";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const bgStyle = {
  sideImage: {
    width: "100%",
    height: "100vh",
  },
} as const;
const ListItemButton = styled(MuiListItemButton)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
function DeleteUser() {
  const { code, number, platform } = useParams();
  const [countryList, setCountryList] = useState(CountryList.countries);
  const [displayCountryListModal, setDisplayCountryListModal] = useState(false);
  const [countryData, setCountryData] = useState({
    code: "",
    number: "",
    icon: "",
  });

  const [deleteAccount, setDeleteAccount] = useState({
    deleteAccount: false,
    verificationCodeSent: false,
    email: "",
    verificationCode: "",
  });

  const [data, setData] = useState({
    CountryCode: code,
    MobileNumber: number,
  });

  // const sendConfirmationCode = useMutation({
  //   mutationKey: ["send-delete-confirmation-code"],
  //   mutationFn: async (data: any) => {
  //     return axios
  //       .post(`https://test-api.meetleelou.com/api/email`, data, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //       .then((res) => {
  //         return res.data;
  //       });
  //   },
  //   onSuccess: () => {
  //     setDeleteAccount({
  //       ...deleteAccount,
  //       verificationCodeSent: true,
  //     });
  //   },
  // });
  const [Loading, setLoading] = React.useState(false);
  const [accountDeleted, setAccountDeleted] = React.useState(false);

  const [showAlert, setShowAlert] = React.useState({
    show: false,
    message: "",
    type: false,
  });

  const sendCC = () => {
    setLoading(true);
    const formDataObject = new FormData();

    formDataObject.append("Email", deleteAccount.email);
    formDataObject.append("MobileNumber", countryData.number);
    formDataObject.append("CountryCode", countryData.code);
    formDataObject.append("EmailType", "1");

    axios
      .post(`https://test-api.meetleelou.com/api/email`, formDataObject, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        if (response?.data?.success) {
          setDeleteAccount({ ...deleteAccount, verificationCodeSent: true });
        } else {
          setShowAlert({
            show: true,
            message: "Something went wrong! Please try again.",
            type: false,
          });
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setShowAlert({
          show: true,
          message: "Something went wrong! Please try again.",
          type: false,
        });
        setLoading(false);
      });
  };
  const getFlagEmoji = (countryCode: any) => {
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char: any) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  };
  const delAccount = () => {
    setLoading(true);
    const formDataObject = new FormData();
    formDataObject.append("DeleteAccountCode", deleteAccount?.verificationCode);
    formDataObject.append("type", "0");
    formDataObject.append("Input", deleteAccount.email);

    axios
      .post(
        `https://test-api.meetleelou.com/api/DeleteAccount`,
        formDataObject,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response: any) => {
        if (response?.data?.success) {
          setAccountDeleted(true);
        } else {
          setShowAlert({
            show: true,
            message: "Something went wrong! Please try again.",
            type: false,
          });
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setShowAlert({
          show: true,
          message: "Something went wrong! Please try again.",
          type: false,
        });
        setLoading(false);
      });
  };

  const goBack = () => {
    if (platform == "android") {
      window.location.href = "au.com.itfirstaid.and.leelou://account/deleted";
    } else {
      window.location.href = "au.com.itfirstaid.ios.leelou://account/deleted";
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Delete User Account" />
      <Grid
        container
        justifyContent="center"
        direction={"row"}
        alignItems="stretch"
      >
        <Grid
          container
          xs={12}
          md={5}
          alignItems="flex-start"
          justifyContent={"center"}
          height="100vh"
        >
          <MuiCard>
            <Grid xs={12} container alignItems="center" p={10}>
              <Grid item xs={12} container justifyContent="center">
                <img
                  height={"80px"}
                  width={"90px"}
                  alt="Leelou Logo"
                  src="/static/img/avatars/leelou-logo.png"
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Typography variant="h2" align="center" width={"100%"}>
                  Leelou
                </Typography>
              </Grid>

              {accountDeleted ? (
                <Grid item xs={12} py={10} container justifyContent="center">
                  <Typography
                    component="h1"
                    variant="h6"
                    align="center"
                    gutterBottom
                    color={grey[500]}
                  >
                    Your account has been successfully deleted!. You can create
                    a new account if you want to continue using our service.
                  </Typography>

                  {/* <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => goBack()}
                    style={{ marginTop: 20, backgroundColor: "#F56C66" }}
                  >
                    Go Back to Leelou
                  </Button> */}
                </Grid>
              ) : !deleteAccount?.verificationCodeSent ? (
                <Grid item xs={12} py={10} container justifyContent="center">
                  <Typography
                    component="h1"
                    variant="h6"
                    align="center"
                    gutterBottom
                    color={grey[500]}
                  >
                    Please enter your country code, phonenumber and email
                    address that you have used to create account with Leelou,
                    and we will send you a delete account verification code in
                    your email.
                  </Typography>
                  <Grid item xs={12} justifyContent="space-between" py={5}>
                    {/* <Button
                      onClick={() => setDisplayCountryListModal(true)}
                      variant="outlined"
                    >
                      {countryData?.code ? countryData.code : "Country Code"}
                    </Button> */}
                    <TextField
                      type="text"
                      name="countryCode"
                      label={"Select Country Code"}
                      value={
                        countryData?.code
                          ? `${countryData?.icon} ${countryData.code}`
                          : ""
                      }
                      error={Boolean(!countryData.code)}
                      onClick={() => setDisplayCountryListModal(true)}
                      contentEditable={false}
                      style={{ width: "30%" }}
                      my={3}
                      variant="outlined"
                      color="warning"
                      sx={{ background: "rgba(0,0,0,0) !important" }}
                    />
                    <TextField
                      type="text"
                      name="Phone Number"
                      label={"Phone Number"}
                      value={countryData?.number}
                      error={Boolean(!countryData.number)}
                      onChange={(event) =>
                        setCountryData({
                          ...countryData,
                          number: event.target.value,
                        })
                      }
                      style={{ width: "60%", marginLeft: "10%" }}
                      my={3}
                      variant="outlined"
                      color="warning"
                      sx={{ background: "rgba(0,0,0,0) !important" }}
                    />
                  </Grid>

                  <TextField
                    type="email"
                    name="email"
                    label={"Email Address"}
                    value={deleteAccount.email}
                    error={Boolean(!deleteAccount.email)}
                    fullWidth
                    onChange={(event) =>
                      setDeleteAccount({
                        ...deleteAccount,
                        email: event.target.value,
                      })
                    }
                    my={3}
                    variant="outlined"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                  />

                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={sendCC}
                    style={{
                      marginTop: 20,
                      backgroundColor: "#F56C66",
                    }}
                    sx={{ color: "#fff" }}
                    loading={Loading}
                    disabled={
                      deleteAccount?.email == "" ||
                      countryData?.number == "" ||
                      countryData?.code == ""
                    }
                  >
                    Delete Account
                  </LoadingButton>
                </Grid>
              ) : (
                <Grid item xs={12} py={10} container justifyContent="center">
                  <Typography
                    component="h1"
                    variant="h6"
                    align="center"
                    gutterBottom
                    color={grey[500]}
                  >
                    Please enter the delete account verification code you have
                    received in your provided email.
                  </Typography>

                  <TextField
                    type="text"
                    name="verificationcode"
                    label={"Verification Code"}
                    value={deleteAccount.verificationCode}
                    error={Boolean(!deleteAccount.verificationCode)}
                    fullWidth
                    onChange={(event) =>
                      setDeleteAccount({
                        ...deleteAccount,
                        verificationCode: event.target.value,
                      })
                    }
                    my={3}
                    variant="outlined"
                    color="warning"
                    sx={{ background: "rgba(0,0,0,0) !important" }}
                  />
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={delAccount}
                    disabled={deleteAccount?.verificationCode == ""}
                    style={{ marginTop: 20, backgroundColor: "#F56C66" }}
                    loading={Loading}
                  >
                    Confirm Delete
                  </LoadingButton>
                  <Typography
                    component="h3"
                    variant="h6"
                    align="center"
                    gutterBottom
                    color={grey[500]}
                    style={{ marginTop: 15 }}
                    onClick={() =>
                      setDeleteAccount({
                        ...deleteAccount,
                        verificationCodeSent: false,
                      })
                    }
                  >
                    Didn't receive verification code? Resend
                  </Typography>
                </Grid>
              )}
            </Grid>
            {showAlert?.show && (
              <Alert
                severity={showAlert?.type ? "success" : "error"}
                onClose={() =>
                  setShowAlert({ show: false, message: "", type: false })
                }
              >
                {showAlert?.message}
              </Alert>
            )}
            <Dialog
              open={displayCountryListModal}
              onClose={() => setDisplayCountryListModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              style={{ height: "100vh" }}
            >
              <DialogTitle id="alert-dialog-title">
                {"Select Country Code"}
              </DialogTitle>
              <DialogContent>
                <List component="nav">
                  <ul>
                    {countryList.map((item, index) => {
                      const ico = getFlagEmoji(item.countryIso);
                      return (
                        <ListItemButton
                          onClick={() => {
                            setCountryData({
                              ...countryData,
                              code: item.country,
                              icon: ico,
                            });
                            setDisplayCountryListModal(false);
                          }}
                        >
                          <ListItemIcon>{ico}</ListItemIcon>
                          <ListItemText primary={`(${item.name.eng})`} />
                        </ListItemButton>
                      );
                    })}
                  </ul>
                </List>
              </DialogContent>
            </Dialog>
          </MuiCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DeleteUser;
