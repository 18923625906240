import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { grey, orange } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import Notification from "../components/Notification";
import axios from "axios";
import stringData from "../stringFile";
import {
  Avatar,
  Button,
  Box,
  CardContent,
  Dialog,
  Chip,
  Checkbox,
  DialogProps,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
  NotInterested as SuspendIcon,
  Clear as ClearIcon,
  VerifiedUser as Approved,
  AccessTime as Pending,
  GpsFixed as LocationIcon,
  AlternateEmail as EmailIcon,
  ContactPhone as PhoneIcon,
  DateRange as DateIcon,
  SupervisorAccount as GenderIcon,
} from "@material-ui/icons";

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function FilterDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [messageOnSuspend, setSuspendMessage] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuspend = () => {
    setOpen(false);
    setSuspendMessage(true);
  };
  const [value, setValue] = React.useState<Date | null>(null);
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { gilad, jason, antoine } = state;

  const [filterdate, setfilterdate] = useState<Date | null>(null);
  const handleResetFilter = () => {
    props.callback(props.data);
    setOpen(false);
  };
  const handleFilterApply = () => {
    const data: any = [];
    const dt = new Date(filterdate ? filterdate : new Date());

    props.data.map((key: any, value: any) => {
      const date = new Date(String(key.lastOnline));

      if (
        date.getDate() == dt.getDate() &&
        date.getMonth() == dt.getMonth() + 1 &&
        date.getFullYear() == dt.getFullYear()
      ) {
        data.push(key);
      }
    });
    props.callback(data);

    setOpen(false);
    setSuspendMessage(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="warning"
        aria-haspopup="true"
        fullWidth={true}
      >
        {stringData.filter.filter}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Search Filters"}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Table>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">Country</StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select the country from the list.
                        </MenuItem>
                        <MenuItem value={1}>Australia</MenuItem>
                        <MenuItem value={2}>USA</MenuItem>
                        <MenuItem value={3}>New Zealand</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                </TableRow>
               
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Device Type
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select device type for user
                        </MenuItem>
                        <MenuItem value={"IOS"}>IOS</MenuItem>
                        <MenuItem value={"Android"}>Android</MenuItem>
                      </Select>
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   Friends Count
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                    <TextField
                      variant="outlined"
                    />
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   Guardains Count
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                    <TextField
                      variant="outlined"
                    />
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                   Dependants Count
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                    <TextField
                      variant="outlined"
                    />
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Has Pending Invites
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select has pending invites status
                        </MenuItem>
                        <MenuItem value={1}>True</MenuItem>
                        <MenuItem value={0}>False</MenuItem>
                      </Select>
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Has Active SOS
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select active sos status for user
                        </MenuItem>
                        <MenuItem value={1}>True</MenuItem>
                        <MenuItem value={0}>False</MenuItem>
                      </Select>
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>

                <TableRow>
                  <StyledTableCell align="right">
                    <Checkbox value="remember" color="warning" />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Login Status
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormControl fullWidth={true}>
                      <Select
                        value={0}
                        fullWidth={true}
                        color="info"
                        size="small"
                      >
                        <MenuItem value={0} disabled>
                          Select the login status for user
                        </MenuItem>
                        <MenuItem value={1}>Logged In</MenuItem>
                        <MenuItem value={0}>Logged Out</MenuItem>
                      </Select>
                    </FormControl>{" "}
                  </StyledTableCell>
                </TableRow>
                </TableBody>
            </Table>
          </Grid>
          {/* <Grid container xs={12} alignItems="center">
            <Typography variant="body2" color={grey[500]}>
              Include suspended accounts:
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  color="warning"
                  checked={antoine}
                  onChange={handleChange}
                  name="antoine"
                />
              }
              label=""
            />
          </Grid> */}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            style={{ display: "flex", justifyContent: "flex-end" }}
            px={6}
          >
            <Button
              onClick={handleResetFilter}
              variant="contained"
              color="warning"
            >
              Clear Filter
            </Button>
            &nbsp; &nbsp;
            <Button onClick={handleFilterApply} variant="outlined">
              Apply & Show Results
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={messageOnSuspend}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Filter has been applied successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FilterDialog;
