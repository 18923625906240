import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { grey, orange } from "@material-ui/core/colors";
import { Helmet } from "react-helmet-async";
import { spacing, width } from "@material-ui/system";
import { darken } from "polished";
import { tableCellClasses } from "@material-ui/core/TableCell";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";
import UserNetworkTabs from "../users/UserNetworkTabs";
import {
  ArrowDropUp as Ascending,
  ArrowDropDown as Descending,
  CheckCircleOutlineRounded,
  Visibility
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, GridSelectionModel} from '@mui/x-data-grid';
import FilterDialog from "../users/FilterDialog";
import {
  CircularProgress,
  Avatar,
  IconButton,
  CardContent,
  Chip,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  Container,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card as DefaultCard,
  Box,
} from "@material-ui/core";
import UsersList from "../dashboardusers/UsersList";
import Loader from "../../components/Loader";
const StyledTableCell = styled(TableCell)({
  padding: "5px",
});
const FixedWidthTableCell = styled(StyledTableCell)({
  width: "50%",
});
export type User = {};

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const BigAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const ACard = styled(MuiCard)(spacing);
const Card = styled(ACard)`
  cursor: pointer;
`;
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

const SearchField = (props: any) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Input
        placeholder={stringData.inputPlaceholder.leelouUserSearch}
        onChange={props.oc}
        value={props.value}
      />
    </Search>
  );
};

function Campaigns(props: any) {
  interface User {
    "User_id":Number;
 "PhoneNumber": String;
      "Code":  String;
      "Status":  String;
      "RegistratedDate":  String;
      "Validation_create_date":  String;
      "completed": Number;
      "attempted": Number;
  }

  interface Campaign{
"created_date":  String;
"description":  String;
"id":  String;
"receivers_count":  Number;
"title":  String;
"type":  String;
  }

  const columns: GridColDef[] = [
   
    { field: 'id', headerName: 'Id', width: 100, disableColumnMenu:true,},

  { field: 'type', headerName: ' Type', width: 150, disableColumnMenu:true, 
  },
  { field: 'title', headerName: 'Title', width: 200, disableColumnMenu:true, 
  },
  { field: 'description', headerName: 'Description', width: 300, disableColumnMenu:true, 
},
{ field: 'receivers_count', headerName: 'Users', width: 100, disableColumnMenu:true, 
},
  { field: 'created_date', headerName: 'Created Date', width: 200, disableColumnMenu:true},

  ];


  const navigate = useNavigate();
  const userList: JSX.Element[] = [];
  const [Users, setUsers] = useState([]);
  const [DefaultUsers, setDefaultUsers] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    displayUsers();
  }, []);

  const displayUsers = () => {
    const timeStamp = Math.floor(Date.now() / 1000);
    console.log(timeStamp);
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Campaign`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setLoading(false);
            const data = res?.data?.Data;
            console.log(data)
            const reversedData = data?.reverse();
            const indexed = reversedData.map((row:any, index:number) => ({ id: index+1, ...row }));
            setUsers(indexed);
            setDefaultUsers(indexed);
          })
          .catch((error) => {
            alert(error)
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  
  const [ViewDetail, setViewDetail] = useState(false);
  const [UserDetail, setUserDetail] = useState({} as Campaign);
  const [SearchTerm, setSearchTerm] = useState("");

  const handleChange = async (event: any) => {
    const term = event.target.value;
    if (term) {
      const results = DefaultUsers.filter((user: User) =>
        user?.PhoneNumber.replace(/\s/g, '').includes(term) ||   user?.Code.toLowerCase().includes(term.toLowerCase())
      );
      setUsers(results);
    } else {
      setUsers(DefaultUsers);
    }
    setSearchTerm(term);
  };



  const viewUserDetail = (data: Campaign) => {
    console.log("USER DETAIL", data)
    setUserDetail(data);
    setViewDetail(true);
  };


  const closeUserDetails = () => {
    setUserDetail({} as Campaign);
    setViewDetail(false);
  };




  return (
    <React.Fragment>
      <Helmet title="Notification Center" />
      {/* <BigAvatar
                  alt={String(key?.FirstName)}
                  src={
                    "http://meetleeloudashboard.com.s3-website-ap-southeast-2.amazonaws.com/" +
                    String(key?.Avatar)
                  }
                /> */}
      <Grid container style={{ height:"100%"}} spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          style={{ backgroundColor:"#fff"}} 
        >
          {/* <Grid container spacing={0} px={2} style={{height:'5%'}}>
            <Grid item xs={9}>
              <SearchField oc={handleChange} value={SearchTerm} />
            </Grid>
            <Grid item xs={3}>
              <FilterDialog data={DefaultUsers} callback={setUsers} />
            </Grid>
          </Grid> */}
 
          <Grid container spacing={0} px={2} style={{height:'95%'}}>
          <DataGrid
        rows={Users}
        columns={columns}
        loading={Loading}
        getRowId={(row) => row.id}
        onRowClick={(data:any) => viewUserDetail(data?.row)}
        checkboxSelection
        disableSelectionOnClick
        
      />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          sx={{
            flexDirection: "row",
            overflowY: "auto", // Enable vertical scrolling
            height: "100%", // Take full height of the parent
          }}
          spacing={2}
        >
          <Grid item xs={12}>
            {UserDetail?.id ? (
             <Card sx={{ width: "100%" }} p={10}>
          <Typography variant="h6" mb={3}>
            {UserDetail?.title}
          </Typography>

          <Box style={{borderRadius:10, backgroundColor:"#f6f6f6"}} p={5} mb={10}>
          {UserDetail?.description}
          </Box>
          Sent To {UserDetail?.receivers_count} Users on {UserDetail?.created_date}

          </Card>
            ) : (
              <Card sx={{ width: "100%" }} pt={0}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                    p={5}
                  >
                    <CardContent>
                      <Typography variant="h5" color={grey[500]}>
                        {"Select campaigns to view details."}
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Campaigns;
