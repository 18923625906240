import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { grey, orange } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import Notification from "../components/Notification";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";

import {
  CircularProgress,
  Avatar,
  Button,
  Box,
  CardContent,
  Dialog,
  Chip,
  Checkbox,
  DialogProps,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Actions from "./Actions";

import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
  NotInterested as SuspendIcon,
  Clear as ClearIcon,
  VerifiedUser as Approved,
  AccessTime as Pending,
  GpsFixed as LocationIcon,
  AlternateEmail as EmailIcon,
  ContactPhone as PhoneIcon,
  DateRange as DateIcon,
  SupervisorAccount as GenderIcon,
  LocationOn as LocationPointerIcon,
  Security as SecurityIcon,
} from "@material-ui/icons";

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function AlertDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [messageOnSuspend, setSuspendMessage] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    setSuspendMessage(false);
  };
  interface User {
    accesss: String;
    companyId: String;
    email: String;
    id: String;
    isLockoutEnabled: Boolean;
    name: String;
    roles: [];
    userName: String;
    zoneIds: [];
    phone: String;
  }
  const authorization_token = localStorage.getItem("auth_session");

  const handleSuspend = async () => {
    const type = props.data.isLockoutEnabled ? "enable" : "disable";
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        await axios
          .put(
            `https://test-adminapi.meetleelou.com/api/auth/user/claims`,
            {
              userId: props.data.id,
              type: "Access",
              value: props.data.isLockoutEnabled ? "Write" : "Read",
            },
            {
              headers: { Authorization: `Bearer ${auth_token.token}` },
            }
          )
          .then((res) => {
            axios
              .post(
                `https://test-adminapi.meetleelou.com/api/auth/user/${type}`,
                { id: props.data.id },
                {
                  headers: { Authorization: `Bearer ${auth_token.token}` },
                }
              )
              .then((res) => {
                props.callback();
                setOpen(false);
                setSuspendMessage(true);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen} size="small">
        <SuspendIcon color="disabled" />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {stringData.info.message}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.data.isLockoutEnabled
              ? stringData.info.unsuspendAdminMesssage
              : stringData.info.suspendAdminMesssage}{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuspend} variant="contained" color="error">
            {props.data.isLockoutEnabled
              ? stringData.button.unsuspend
              : stringData.button.suspend}{" "}
          </Button>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            {stringData.button.cancel}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={messageOnSuspend}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.data.isLockoutEnabled
              ? stringData.info.unsuspendAdminSuccess
              : stringData.info.suspendAdminSuccess}{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            {stringData.button.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
