import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { grey, orange } from "@material-ui/core/colors";
import { Helmet } from "react-helmet-async";
import Actions from "./Actions";
import FilterDialog from "./FilterDialog";
import { spacing } from "@material-ui/system";
import { darken } from "polished";
import { tableCellClasses } from "@material-ui/core/TableCell";
import { AlignCenter } from "react-feather";
import { useNavigate } from "react-router-dom";
import UserDetailCard from "./UserDetailCard";
import { yellow, red, green, purple } from "@material-ui/core/colors";
import stringData from "../stringFile";
import Loader from "../../components/Loader";
import {
  Avatar,
  Button,
  Box,
  CardContent,
  Chip,
  Grid,
  Link,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Fab as MuiFab,
  InputBase,
  TableCell,
  TableRow,
} from "@material-ui/core";
import {
  Clear,
  Search as SearchIcon,
  ArrowDropUp as Ascending,
  ArrowDropDown as Descending,
} from "@material-ui/icons";

const FilterGrid = styled(Grid)`
  background-color: #efefef;
  border-radius: 5px;
`;
const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const PendingChip = styled(Chip)({
  //pending cancel
  backgroundColor: "#e5a83a",
  color: "#fff",
});
const CancelledChip = styled(Chip)({
  //cancelled
  backgroundColor: red[500],
  color: "#fff",
});
const FalseStartChip = styled(Chip)({
  //falsestart
  backgroundColor: "#a4a4fd",
  color: "#fff",
});
const ActiveChip = styled(Chip)({
  //active
  backgroundColor: "#51b49a",
  color: "#fff",
});

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const ACard = styled(MuiCard)(spacing);
const Card = styled(ACard)`
  cursor: pointer;
`;
const WhiteGrid = styled(Grid)`
  background-color: #fff;
  border-radius: 5px;
`;
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: 10px;
    width: 100%;
  }
`;

export type User = {};
const SearchField = (props: any) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Input
        placeholder={"Search User By Name..."}
        onChange={props.oc}
        value={props.value}
      />
    </Search>
  );
};
function UsersList(props: any) {
  interface User {
    Roles: [];
    Id: String;
    UserName: String;
    Name: String;
    Phone: String;
    Email: String;
    ZoneIds: [];
    Access: String;
    CompanyId: String;
    IsLockoutEnabled: Boolean;
  }

  const navigate = useNavigate();
  const userList: JSX.Element[] = [];
  const [Users, setUsers] = useState([]);
  const [DefaultUsers, setDefaultUsers] = useState([]);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    displayUsers();
  }, []);


  
  const displayUsers = () => {
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      console.log(authorization_token);
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`https://test-adminapi.meetleelou.com/api/auth/users`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res) => {
            setLoading(false);

            setUsers(res?.data);
            setDefaultUsers(res?.data);
            setViewDetail(false);
            if (ViewDetail) {
              viewUserDetail(UserDetail);
            }
          })
          .catch((error) => {
            setLoading(false);

            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const [ViewDetail, setViewDetail] = useState(false);
  const [UserDetail, setUserDetail] = useState({} as User);
  const [SearchTerm, setSearchTerm] = useState("");

  const handleChange = async (event: any) => {
    const term = event.target.value;
    if (term) {
      const results = DefaultUsers.filter((user: User) =>
        user?.Name?.toLowerCase().includes(term.toLowerCase())
      );
      setUsers(results);
    } else {
      setUsers(DefaultUsers);
    }
    setSearchTerm(term);
  };

  const viewUserDetail = async (key: User) => {
    setUserDetail(key);
    setViewDetail(true);
  };
  const [sortByNameType, setsortByNameType] = useState(1);
  const handleSortByName = (type: number) => {
    if (type) {
      let newdata = [].concat(Users).sort((a: any, b: any) => {
        return a.name.toLowerCase()[0] < b.name.toLowerCase()[0] ? -1 : 1;
      });
      setUsers(newdata);
      setsortByNameType(0);
    } else {
      let newdata = [].concat(Users).sort((a: any, b: any) => {
        return a.name.toLowerCase()[0] < b.name.toLowerCase()[0] ? 1 : -1;
      });
      setUsers(newdata);
      setsortByNameType(1);
    }
  };

  const UserCard = (key: User) => {
    return (
      <Grid item xs={12} sm={12} py={1}>
        <Card onClick={() => viewUserDetail(key)}>
          <CardContent style={{ padding: "8px" }}>
            <Grid
              container
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Grid item alignItems="center">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BigAvatar alt={String(key.Name)} src={String(key.Name)} />
                  <Typography variant="body2" ml={3}>
                    {key.UserName}
                  </Typography>
                </Box>
              </Grid>

              <Grid item alignItems="flex-end">
                <Typography variant="body2" color={grey[500]}>
                  {key.IsLockoutEnabled ? (
                    <Chip
                      size="small"
                      label={stringData.dUsers.suspended}
                      color="error"
                    />
                  ) : (
                    <Chip
                      size="small"
                      label={stringData.dUsers.active}
                      color="success"
                    />
                  )}
                </Typography>
              </Grid>
              {/* <Grid item xs={1} alignItems="center" justifyContent="flex-end">
                <AlertDialog
                  data={key}
                  callback={displayUsers}
                  reloadUserDetailCard={viewUserDetail}
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Grid container sx={{ height: "90vh" }} spacing={2}>
        <Grid
          item
          xs={3}
          md={2}
          sx={{
            backgroundColor: "#fff",
            flexDirection: "row",
            overflowY: "auto", // Enable vertical scrolling
            height: "100%", // Take full height of the parent
          }}
          spacing={2}
        >
          <Grid container>
            <Grid container xs={12} pb={2} direction="row">
              <Grid item xs={6}>
                <FilterGrid container xs={12}>
                  <Grid
                    item
                    xs={10}
                    alignItems="center"
                    style={{ display: "flex", justifyItems: "flex-start" }}
                    pl={2}
                  >
                    {" "}
                    <SearchIcon color={"disabled"} />
                    <Input
                      placeholder={
                        stringData.inputPlaceholder.dashboardUserSearch
                      }
                      onChange={handleChange}
                      value={SearchTerm}
                      sx={{ paddingLeft: "10px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    alignItems="center"
                    style={{ display: "flex", justifyContent: "right" }}
                    pr={2}
                  >
                    <Clear
                      color={"disabled"}
                      style={{ cursor: "pointer" }}
                      onClick={() => (
                        setUsers(DefaultUsers), setSearchTerm("")
                      )}
                    />
                  </Grid>
                </FilterGrid>
              </Grid>
              <Grid item xs={1}></Grid>
              <WhiteGrid item xs={5}>
                <Actions
                  data={Users}
                  callback={setUsers}
                  defaultData={DefaultUsers}
                />
              </WhiteGrid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                maxHeight: "78vh",
                overflowY: "scroll",
                position: "relative",
                marginBottom: "20px",
              }}
            >
              <Grid item xs={12} mb={1}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent={"space-between"}
                  padding={"8px"}
                >
                  <Grid
                    item
                    onClick={() => handleSortByName(sortByNameType)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color={grey[800]}
                      alignItems="center"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {stringData.tableHeader.name}{" "}
                      {sortByNameType ? (
                        <Ascending></Ascending>
                      ) : (
                        <Descending></Descending>
                      )}
                    </Typography>
                  </Grid>

                  <Grid item alignItems="center">
                    <Typography variant="body2">
                      {stringData.tableHeader.status}
                    </Typography>
                  </Grid>
                  {/* <Grid
                      item
                      xs={1}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {stringData.tableHeader.actions}
                    </Grid> */}
                </Grid>
              </Grid>
              {Loading ? <Loader /> : Users?.map(UserCard)}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3} />

        <Grid item xs={6} pl={3}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
            pb={6}
          >
            <Link href="/users/dashboard/roleconfig">
              <Button size="small" variant="contained" color="warning">
                {stringData.dUsers.roleConfiguration}
              </Button>
            </Link>
            <Link href="/users/dashboard/createadmin">
              <Button
                size="small"
                variant="contained"
                color="warning"
                sx={{ marginLeft: "5px" }}
              >
                {stringData.dUsers.addNewAdmin}
              </Button>
            </Link>
          </Grid>
          {ViewDetail ? (
            <UserDetailCard
              key={UserDetail?.Id}
              data={UserDetail}
              callback={displayUsers}
              callback1={setViewDetail}
            />
          ) : (
            <Card sx={{ width: "100%" }} pt={0}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                  p={5}
                >
                  <CardContent>
                    <Typography variant="h5" color={grey[500]}>
                      {stringData.info.userViewDetail}
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UsersList;
