import React from "react";
import styled from "styled-components/macro";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useContext } from "react";

import {
  CardContent,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Slide,
  Snackbar,
  SnackbarOrigin,
  Typography,
  ListProps,
  SlideProps,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { GlobalContext } from "../../contexts/GlobalContext";

const QuickAlert = () => {
  const globalContext = useContext(GlobalContext);
  const handleClose = () => {
    globalContext?.setDisplayQuickAlert({
      ...globalContext?.displayQuickAlert,
      open: false,
    });
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={2000}
      open={globalContext?.displayQuickAlert.open}
      onClose={handleClose}
      message={globalContext?.displayQuickAlert.message}
      key={"alert"}
    />
  );
};

export default QuickAlert;
