import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

function Loading(props: any) {
  return (
    <Grid
      item
      xs={12}
      py={8}
      display={props.loading}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <CircularProgress color="warning" size={30} />
    </Grid>
  );
}

export default Loading;
