import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { grey, red } from "@material-ui/core/colors";
import { tableCellClasses } from "@material-ui/core/TableCell";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import stringData from "../stringFile";
import {
  Avatar,
  Button,
  CardContent,
  Chip,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  TableCell,
  Tooltip,
} from "@material-ui/core";
import Actions from "./Actions";
import GoogleMapReact, { Maps } from "google-map-react";
import { spacing } from "@material-ui/system";
import {
  Favorite as FavoriteIcon,
  FiberManualRecord as StartIcon,
  Stop as StopIcon,
  LocationOn,
  LocationOff,
  Mic as MicOn,
  MicOff as MicOff,
  NotificationsActive as NotificationOn,
  NotificationsOff as NotificationOff,
  Battery20,
  Battery50,
  Battery60,
  Battery90,
  Battery30,
  BatteryFull,
  BatteryUnknown,
  OpenInNew,
} from "@material-ui/icons";
import { SosType } from "./SosTypes";
import GetAddress from "./GetAddress";
import ReactAudioPlayer from "react-audio-player";
import Loading from "../dashboardusers/Loading";
import { GlobalContext } from "../../contexts/GlobalContext";
const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
const GoogleMapReactWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 90;
  height: 90;
`;

const PendingChip = styled(Chip)({
  //pending cancel
  backgroundColor: "#e5a83a",
  color: "#fff",
});
const CancelledChip = styled(Chip)({
  //cancelled
  backgroundColor: red[500],
  color: "#fff",
});
const FalseStartChip = styled(Chip)({
  //falsestart
  backgroundColor: "#a4a4fd",
  color: "#fff",
});
const ActiveChip = styled(Chip)({
  //active
  backgroundColor: "#51b49a",
  color: "#fff",
});
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function SosUserDetails(props: any) {
  const [SosDetail, setSosDetail] = React.useState(props.data as SosType);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSosDetail(props.data);
    console.log("AUDIO ID", props.data.AudioId);
    GetSosAudio(props.data.AudioId);
  }, [props]);

  const [Audios, setAudios] = React.useState([] as any);
  const [AudioLoaded, setAudioLoaded] = React.useState(false);

  const GetSosAudio = (audioId: string) => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Sos/audio?audioId=${audioId}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res: any) => {
            console.log("AUDIOS", res);
            setLoading(false);
            setAudios(res.data.files);
            setAudioLoaded(true);
          })
          .catch((error: any) => {
            setAudioLoaded(true);
            setLoading(false);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  // const AudioList = (key: any) => {
  //   return <AudioPlayer src={key} style={{ borderRadius: 10, margin: 10 }} />;
  // };

  const lastUpdate = new Date(String(SosDetail?.LastUpdate));
  const startTime = new Date(String(SosDetail?.CreateDate));
  const options = {
    center: {
      lat: Number(SosDetail?.VictimUserDetail?.Activity?.Latitude),
      lng: Number(SosDetail?.VictimUserDetail?.Activity?.Longitude),
    },
    zoom: 14,
  };

  const getMapOptions = (maps: Maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: true,
    };
  };

  const Marker = (props: any) => {
    const { color, name, id } = props;
    return (
      <>
        <div
          className={props.current ? "current pin bounce" : "breadcrumbs"}
          style={{ backgroundColor: color, cursor: "pointer" }}
        ></div>
        {props.current ? <Grid className="tooltip">{props.name}</Grid> : <></>}
      </>
    );
  };

  return (
    <Card sx={{ width: "100%" }} pt={0}>
      <CardContent>
        <Grid container direction="row" mb={5}>
          <Grid
            item
            xs={1}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              alt={String(SosDetail?.VictimUserDetail?.User?.DisplayName)}
              src={String(SosDetail?.VictimUserDetail?.User?.DisplayName)}
            />
          </Grid>
          <Grid
            item
            xs={7}
            md={3}
            style={{ alignItems: "flex-start", justifyContent: "flex-start" }}
            direction="column"
          >
            <Typography variant="h6">
              {SosDetail?.VictimUserDetail?.User?.DisplayName}
            </Typography>
            <Typography variant="body2" color={grey[500]} gutterBottom>
              {stringData.tableHeader.userId}:{" "}
              {SosDetail?.VictimUserDetail?.User?.UserId}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            md={2}
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            {SosDetail?.Status == "Active" ? (
              <ActiveChip
                size="small"
                label={stringData.filter.active}
                style={{ backgroundColor: "#9fe9a5" }}
              />
            ) : (
              ""
            )}
            {SosDetail?.Status == "PendingCancel" ? (
              <PendingChip
                size="small"
                label={stringData.filter.pendingCancel}
                style={{ width: "100%", backgroundColor: "#e8e2ae" }}
              />
            ) : (
              ""
            )}
            {SosDetail?.Status == "Cancelled" ? (
              <CancelledChip
                size="small"
                label={stringData.filter.cancelled}
                style={{ backgroundColor: "#f49192" }}
              />
            ) : (
              ""
            )}
            {SosDetail?.Status == "FalseStart" ? (
              <FalseStartChip
                size="small"
                label={stringData.filter.falseStart}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={1}>
            <StartIcon color="warning" />
          </Grid>
          <Grid item xs={3} md={3}>
            {stringData.sosDetails.startTime}:
          </Grid>
          <Grid item xs={6}>
            {`${months[startTime.getMonth() - 1]} ${startTime.getDate()}`},{" "}
            {startTime.getFullYear()}{" "}
            {`${startTime.getHours()}:${startTime.getMinutes()}`}{" "}
            {startTime.getHours() > 11 ? "PM" : "AM"}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={1}>
            <StopIcon color="warning" />
          </Grid>
          <Grid item xs={3} md={3}>
            {stringData.sosDetails.lastOnline}:
          </Grid>
          <Grid item xs={6}>
            {`${months[lastUpdate.getMonth() - 1]} ${lastUpdate.getDate()}`},{" "}
            {lastUpdate.getFullYear()}{" "}
            {`${lastUpdate.getHours()}:${lastUpdate.getMinutes()}`}{" "}
            {lastUpdate.getHours() > 11 ? "PM" : "AM"}
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={1}>
            <LocationOn color="warning" />
          </Grid>
          <Grid item xs={3} md={3}>
            {stringData.sosDetails.sharedLocation}:
          </Grid>
          <Grid item xs={6}>
            <GetAddress
              location={{
                latitude: SosDetail?.VictimUserDetail?.Activity?.Latitude,
                longitude: SosDetail?.VictimUserDetail?.Activity?.Longitude,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2} spacing={50}>
          <Tooltip
            title={`${SosDetail?.VictimUserDetail?.User?.DisplayName} has ${SosDetail?.VictimUserDetail?.Activity?.Battery}% Battery`}
            arrow
            placement="top"
          >
            {SosDetail?.VictimUserDetail?.Activity?.Battery > 50 ? (
              SosDetail?.VictimUserDetail?.Activity?.Battery > 90 ? (
                <Battery90 color="warning" />
              ) : SosDetail?.VictimUserDetail?.Activity?.Battery > 60 ? (
                <Battery60 color="warning" />
              ) : SosDetail?.VictimUserDetail?.Activity?.Battery > 50 ? (
                <Battery50 color="warning" />
              ) : (
                <Battery50 color="warning" />
              )
            ) : SosDetail?.VictimUserDetail?.Activity?.Battery <= 20 ? (
              <Battery20 color="warning" />
            ) : SosDetail?.VictimUserDetail?.Activity?.Battery < 50 ? (
              <Battery30 color="warning" />
            ) : (
              <Battery50 color="warning" />
            )}
          </Tooltip>
          <Tooltip
            title={`${SosDetail?.VictimUserDetail?.User?.DisplayName} has ${
              SosDetail?.NotificationsEnabled ? "enabled" : "disabled"
            } notification permission.`}
            arrow
            placement="top"
          >
            {SosDetail?.NotificationsEnabled ? (
              <NotificationOn color="warning" />
            ) : (
              <NotificationOff color="disabled" />
            )}
          </Tooltip>
          <Tooltip
            title={`${SosDetail?.VictimUserDetail?.User?.DisplayName} has ${
              SosDetail?.MicEnabled ? "enabled" : "disabled"
            } microphone permission.`}
            arrow
            placement="top"
          >
            {SosDetail?.MicEnabled ? (
              <MicOn color="warning" />
            ) : (
              <MicOff color="disabled" />
            )}
          </Tooltip>
          <Tooltip
            title={`${SosDetail?.VictimUserDetail?.User?.DisplayName} has ${
              SosDetail?.LocationEnabled ? "enabled" : "disabled"
            } location permission.`}
            arrow
            placement="top"
          >
            {SosDetail?.LocationEnabled ? (
              <LocationOn color="warning" />
            ) : (
              <LocationOff color="disabled" />
            )}
          </Tooltip>
        </Grid>
        {!props.viewAudio ? <Divider my={6} /> : <></>}

        {props.viewAudio ? (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1" mb={2} color={grey[800]}>
                {stringData.sosDetails.sharedVoicemails}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ maxHeight: 200, overflow: "scroll", paddingRight: 20 }}
            >
              {/* {AudioLoaded && Audios.map(AudioList)} */}
              <ReactAudioPlayer src={Audios[0]} autoPlay={false} controls />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Link
                href={"/sos/details/" + SosDetail.Guid}
                underline="none"
                target="_blank"
              >
                <Button variant="outlined" color="warning">
                  {stringData.button.viewDetails}
                  <OpenInNew
                    fontSize="small"
                    style={{ paddingLeft: "5px" }}
                  ></OpenInNew>
                </Button>
              </Link>
            </Grid>
          </Grid>
        )}
        {!props.viewAudio && (
          <Grid container style={{ marginTop: 10 }}>
            <GoogleMapReactWrapper>
              <GoogleMapReact
                options={getMapOptions}
                bootstrapURLKeys={{
                  key: "AIzaSyCJel-xS3Dd7M_5bXYBJOt8wPa2IXbmYeE",
                }}
                defaultCenter={{
                  lat: Number(SosDetail?.VictimUserDetail?.Activity?.Latitude),
                  lng: Number(SosDetail?.VictimUserDetail?.Activity?.Longitude),
                }}
                defaultZoom={14}
              >
                <Marker
                  lat={Number(SosDetail?.VictimUserDetail?.Activity?.Latitude)}
                  lng={Number(SosDetail?.VictimUserDetail?.Activity?.Longitude)}
                  name={SosDetail?.VictimUserDetail?.User?.DisplayName}
                  color="#138908f5"
                  current={true}
                />
              </GoogleMapReact>
            </GoogleMapReactWrapper>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default SosUserDetails;
