
import React,{useState, useEffect} from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { rgba } from "polished";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import axios from "axios";
const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

const SuccessfulSOS = ({ theme }: ThemeProps<Theme>) => {
  const secondDatasetColor = rgba(theme.palette.warning.main, 0.7);
  const[data, setData]= useState({} as any);

  useEffect(() => {
    fetchSosCount();
  }, []);


  const extractDatesAndCounts = (Data:any) => {
    const dates: Date[] = [];
    const counts: any[] = [];

    Data.forEach((item: { Date: string | number | Date; Count: any; }) => {
      dates.push(new Date(item.Date)); // Push date object
      counts.push(item.Count); // Push count
    });

    return { dates, counts };
  };

  
  const fetchSosCount = async () => {
    try {
      const response = await axios.get(
        "https://test-adminapi.meetleelou.com/api/Dashboard/sos-count-date",
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if(response?.data?.Status){
        const data = response?.data?.Data;
        const{dates, counts} = extractDatesAndCounts(data)
        const chartData = {
          labels: dates,
          datasets: [
       {
             
              label: "SOS",
              backgroundColor: secondDatasetColor,
              borderColor: secondDatasetColor,
              hoverBackgroundColor: secondDatasetColor,
              hoverBorderColor: secondDatasetColor,
              data: counts,
              barPercentage: 0.5,
              categoryPercentage: 0.5,
              tension: 0.3,
              
            },
      
          ],
          
        };
      
        setData(chartData);


      }
    

    } catch (error) {
      console.log(error);
    }
  };


  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        align: "center", //start for left align'
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          fontColor: theme.palette.text.secondary,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false // This hides the x-axis labels
        }
      }

    },
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="SOS Activated"
      />

      <CardContent>
        <ChartWrapper>
          <Chart type="bar" data={data?data:{}} options={chartOptions} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(SuccessfulSOS);
