import React, { useEffect } from "react";
import styled from "styled-components/macro";
import axios from "axios";
import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  CardContent,
  Avatar,
  Fab,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Button,
} from "@material-ui/core";
import { PlayCircle } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import SendIcon from "@material-ui/icons/Send";
import stringData from "../stringFile";
import ReactAudioPlayer from "react-audio-player";
import Loading from "../dashboardusers/Loading";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)<{ component: React.ReactNode }>`
  width: 100%;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatMain = styled(Grid)``;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: 20vh;
`;

const ChatMessage = styled.div<{ position: "left" | "right" }>`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: left;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;
const Spacer = styled.div(spacing);

const ChatMessageBubble = styled.div<{ highlighted: boolean }>`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  padding: ${(props) => props.theme.spacing(5)};
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

type ChatMessageComponentType = {
  name: string;
  message: string;
  time: number;
  avatar: string;
  position: "left" | "right";
  type: number;
  filename: string;
};

function getHumanReadableTime(timestamp: number) {
  const currentTime = Math.floor(Date.now() / 1000);
  const timeDiff = currentTime - timestamp;

  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const secondsInDay = secondsInHour * 24;
  const secondsInMonth = secondsInDay * 30;
  const secondsInYear = secondsInDay * 365;

  if (timeDiff < secondsInMinute) {
    return "Just now";
  } else if (timeDiff < secondsInHour) {
    const minutes = Math.floor(timeDiff / secondsInMinute);
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else if (timeDiff < secondsInDay) {
    const hours = Math.floor(timeDiff / secondsInHour);
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (timeDiff < secondsInMonth) {
    const days = Math.floor(timeDiff / secondsInDay);
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else {
    const date = new Date(timestamp * 1000);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options as {});
  }
}

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "left",
  type,
  filename,
}: ChatMessageComponentType) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageBubble highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          {type == 0 ? (
            <Typography variant="body2">{message}</Typography>
          ) : type == 1 ? (
            <img
              src={filename}
              style={{ height: 150, width: 150, borderRadius: 10 }}
            />
          ) : type == 3 ? (
            <ReactAudioPlayer src={filename} autoPlay={false} controls />
          ) : (
            <></>
          )}
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">
          {getHumanReadableTime(time)}
        </ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

type Participant = {
  UserId: number;
  DisplayName: string;
  ConversationId: number;
  LastMessageReadId: number;
};

function SosChat(props: any) {
  const [loading, setLoading] = React.useState(false);
  const [messages, setMessages] = React.useState({} as any);
  const [participants, setparticipants] = React.useState({} as any);
  const [emptyMessage, setEmptyMessage] = React.useState(false);

  const { VictimUserDetail } = props?.data;
  useEffect(() => {
    GetConversationMessages();
  }, [props?.data]);

  const GetConversationMessages = () => {
    const authorization_token = localStorage.getItem("auth_session");
    //console.log(authorization_token);
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/Sos/SOSConversations?sosId=${
              props?.data?.Id
            }&CurrentPage=${1}&PageSize=${100}`,
            {
              headers: { Authorization: `Bearer ${auth_token.token}` },
            }
          )
          .then((res: any) => {
            setLoading(false);
            const participantsObject = res?.data?.Participants.reduce(
              (acc: any, participant: Participant) => {
                acc[participant.UserId] = participant;
                return acc;
              },
              {}
            );
            setparticipants(participantsObject);
            setMessages(res?.data);
            setEmptyMessage(res?.data?.Messages?.length <= 0);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  };

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6">{stringData.sosDetails.chat}</Typography>
        <Spacer mb={3} />
        <ChatContainer container component={Card}>
          {loading ? (
            <Loading />
          ) : emptyMessage ? (
            <Typography variant="h6">No Conversations Found</Typography>
          ) : (
            <></>
          )}
          <ChatMain item xs={12}>
            <ChatMessages
              style={{
                overflowY: "scroll",
              }}
            >
              {messages?.Messages?.map((message: any) => {
                const user = participants[message?.userid];
                console.log(user);
                return (
                  <ChatMessageComponent
                    name={participants[message?.userid]?.DisplayName}
                    avatar="/static/img/avatars/avatar-2.jpg"
                    message={message?.message}
                    type={message?.messageType}
                    time={message?.timestamp}
                    position={
                      VictimUserDetail?.User?.UserId == message?.userid
                        ? "right"
                        : "left"
                    }
                    filename={message?.filename}
                  />
                );
              })}
            </ChatMessages>
          </ChatMain>
        </ChatContainer>
        <ChatInput container>
          <Grid item xs={8} sm={10} md={11}>
            <TextField
              variant="outlined"
              label="Type your message"
              fullWidth
              size="small"
              disabled={true}
            />
          </Grid>
          <Grid item xs={4} sm={2} md={1}>
            <Box ml={2}>
              <Fab color="primary" aria-label="add" size="small">
                <SendIcon />
              </Fab>
            </Box>
          </Grid>
        </ChatInput>
      </CardContent>
    </Card>
  );
}

export default SosChat;
