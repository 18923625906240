import * as React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { green, red, grey } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";
import { Settings, ExitToApp, AccountCircle } from "@material-ui/icons";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Divider as MuiDivider,
  Typography,
  Link,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import useAuth from "../../hooks/useAuth";
const Divider = styled(MuiDivider)(spacing);

const IconButton = styled(MuiIconButton)`
  svg { 
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    localStorage.removeItem("auth_session");
    navigate("/auth/sign-in");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <img
            height={"25px"}
            width={"25px"}
            alt="Leelou Logo"
            src="/static/img/avatars/leelou-logo.png"
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>
          <AccountCircle></AccountCircle>

          <Link ml={1} color={grey[800]} underline={"none"}>
            {localStorage.getItem("leelou_aun")}
          </Link>
        </MenuItem>
        <Divider my={6} />
        <MenuItem onClick={closeMenu}>
          <Settings></Settings>{" "}
          <Link
            href="/pages/settings"
            ml={1}
            color={grey[800]}
            underline={"none"}
          >
            Account Setting
          </Link>
        </MenuItem>
        <Divider my={6} />
        <MenuItem onClick={handleSignOut}>
          <ExitToApp></ExitToApp>

          <Link ml={1} color={grey[800]} underline={"none"}>
            Log Out
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
