import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import {
  Typography,
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";

const AppBar = styled(MuiAppBar)`
  background: #fff !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

type NavbarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
  title: string;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");

  const urlChange = useLocation();
  useEffect(() => {
    const title = getTitle(window.location.pathname);
  }, [urlChange]);

  const getTitle = (path: string) => {
    if (path == "/") {
      setTitle("Dashboard");
    } else if (path == "/") {
      setTitle("Home");
    } else if (path == "/sos") {
      setTitle("SOS Alerts");
    } else if (path == "/dashboard") {
      setTitle("Dashboard");
    } else if (path == "/users/dashboard") {
      setTitle("Admin Users");
    } else if (path == "/users/dashboard/roleconfig") {
      setTitle("Role Configuration");
    } else if (path == "/users/leelou") {
      setTitle("Leelou App Users");
    } else if (path == "/feedback") {
      setTitle("Feedbacks");
    } else if (path == "/settings") {
      setTitle("Settings");
    } else if (path == "/dashboard/roleconfig") {
      setTitle("Role Configuration");
    } else if (path == "/users/dashboard/createadmin") {
      setTitle("Create Admin");
    }
    else if (path == "/preregistration") {
      setTitle("Pre-Registration Data");
    }
    else if (path == "/notificationcenter") {
      setTitle("Notification Center");
    }
      else {
      setTitle("SOS Alert Detail");
    }
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Helmet title={title} />
              <Typography
                variant="h3"
                paddingLeft={"20px"}
                style={{ color: "rgba(0,0,0,0.87)" }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid item xs />
            <Grid item>
              {0 ? <NavbarMessagesDropdown /> : <></>}
              {0 ? <NavbarNotificationsDropdown /> : <></>}
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
