import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { grey, orange } from "@material-ui/core/colors";
import { Helmet } from "react-helmet-async";
import Actions from "./Actions";
import FilterDialog from "./FilterDialog";
import { spacing } from "@material-ui/system";
import { darken } from "polished";
import { tableCellClasses } from "@material-ui/core/TableCell";
import { AlignCenter, Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import stringData from "../stringFile";
import { GlobalContext } from "../../contexts/GlobalContext";
import {
  GpsFixed as LocationIcon,
  AlternateEmail as EmailIcon,
  ContactPhone as PhoneIcon,
  DateRange as DateIcon,
  SupervisorAccount as GenderIcon,
  LocationOn as LocationPointerIcon,
  Security as SecurityIcon,
  Accessibility as PermisisonIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import {
  CircularProgress,
  Avatar,
  Button,
  Checkbox,
  CardContent,
  Chip,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  Avatar as MuiAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card as DefaultCard,
} from "@material-ui/core";
import Loading from "./Loading";
import AlertDialog from "./AltertDialog";
import { Navigate } from "react-router-dom";
import DeleteAdminButton from "./DeleteAdminButton";

const StyledTableCell = styled(TableCell)({
  padding: "10px",
});

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const FixedWidthTableCell = styled(StyledTableCell)({
  width: "50%",
});
const ACard = styled(MuiCard)(spacing);
const Card = styled(ACard)`
  cursor: pointer;
`;
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function UserDetailCard(props: any) {
  interface User {
    Email: string;
    Name: string;
    UserName: string;
    Phone: string | null;
    CompanyId: string;
    Address: string;
    Country: string;
    SecuritySuites: string;
    ProfileImage: string | null;
    image: string | null;
    Password: string;
    isViewOnly: boolean;
    ZoneId: string;
    IsLockoutEnabled: boolean;
    ZoneIds: string[];
    ClaimTypes: string | null;
    ClaimValues: string | null;
    Version: number;
    CreatedOn: string; // It's recommended to use Date type here instead of string
    Claims: {
      Type: string;
      Value: string;
      Issuer: string;
    }[];
    Roles: string[];
    Logins: any[]; // You might want to define a type for this if you know its structure
    Tokens: any[]; // You might want to define a type for this if you know its structure
    Id: string;
    NormalizedUserName: string;
    NormalizedEmail: string;
    EmailConfirmed: boolean;
    PasswordHash: string | null;
    SecurityStamp: string;
    ConcurrencyStamp: string;
    PhoneNumber: string | null;
    PhoneNumberConfirmed: boolean;
    TwoFactorEnabled: boolean;
    LockoutEnd: string | null; // It's recommended to use Date type here instead of string
    LockoutEnabled: boolean;
    AccessFailedCount: number;
  }

  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [OtherDetails, setOtherDetails] = useState([] as any);
  const [LoadingDetails, setLoadingDetails] = useState(false);
  const [UserDetail, setUserDetail] = useState(props.data);
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    setLoading(true);
    getOtherDetail();
  }, []);

  const getOtherDetail = () => {
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      console.log(authorization_token);
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(
            `https://test-adminapi.meetleelou.com/api/auth/user/${props.data.Id}`,
            { headers: { Authorization: `Bearer ${auth_token.token}` } }
          )
          .then((res) => {
            setOtherDetails(res.data);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      navigate("/auth/sign-in");
    }
  };

  return (
    <Grid item xs={12}>
      <DefaultCard>
        <CardContent>
          <Grid container>
            <Grid item xs={1} alignItems="center">
              <ExtraBigAvatar
                alt={String(OtherDetails.Name)}
                src={String(OtherDetails.Name)}
              />
            </Grid>
            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "flex-start",
              }}
              direction="column"
              px={5}
            >
              <Typography variant="h6">{OtherDetails.Name}</Typography>

              <Typography variant="body2" color={grey[500]} gutterBottom>
                {stringData.dUsers.username}: {OtherDetails.UserName} <br></br>
                {stringData.dUsers.userId}: {OtherDetails.Id}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <AlertDialog data={props.data} callback={props.callback} />
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Table>
              <TableBody>
                <TableRow>
                  <StyledTableCell align="center">
                    <SecurityIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.dUsers.securitySuits}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {OtherDetails.Id}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <GenderIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.dUsers.adminRoles}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {UserDetail.Roles}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <EmailIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.dUsers.email}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {OtherDetails.normalizedEmail
                      ? OtherDetails.NormalizedEmail.toLowerCase()
                      : OtherDetails.NormalizedEmail}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <PhoneIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.dUsers.phone}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {OtherDetails.Phone}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <LocationPointerIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.dUsers.country}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {OtherDetails.Country}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <LocationIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.dUsers.address}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {OtherDetails.Address}
                  </FixedWidthTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="center">
                    <PermisisonIcon color="warning" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {stringData.dUsers.permissions}
                  </StyledTableCell>
                  <FixedWidthTableCell align="left">
                    {OtherDetails.ClaimValues}
                  </FixedWidthTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid
            container
            mt={3}
            alignItems="right"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              size="small"
              variant="outlined"
              color="warning"
              sx={{ marginX: "5px" }}
              href={`/users/dashboard/editadmin/${UserDetail.Id}`}
            >
              <EditIcon color="warning" />
            </Button>
            <DeleteAdminButton
              data={UserDetail}
              parentComp={props.callback}
              callback1={props.callback1}
            ></DeleteAdminButton>
          </Grid>
        </CardContent>
      </DefaultCard>
    </Grid>
  );
}

export default UserDetailCard;
