import React, { useState, useEffect , useContext} from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SelectChangeEvent } from "@material-ui/core/Select";
import axios from "axios";
import { useQuery,useMutation , QueryCache } from "@tanstack/react-query";
import { getResponseError } from "./errorUtils";

import { UserContext } from "./UserContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Select,
  Input,
  MenuItem,
  Switch,
  InputLabel,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { BASE_URL } from "../../api/ApiEndPoints";
import { CloudUpload as MuiCloudUpload, Superscript } from "@material-ui/icons";
import { spacing, SpacingProps } from "@material-ui/system";
import { boolean } from "yup";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);


interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);



const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;




function CreateForm() {
  interface Admin {
    email: string;
    name: string;
    userName: string;
    phone: string;
    companyId: String;
    roles: [];
    isViewOnly: Boolean;
    zoneIds: String;
  }
  interface Role {
    Id: String;
    Name: String;
  }
 

interface AdminData {
    Email: string,
    Name: string,
    UserName: string,
    Phone: string,
    CompanyId: string,
    Roles: [],
    Address: string,
    Country: string,
    SecuritySuites: string,
    isViewOnly: string,
    ZoneId: string,
    ProfileImage: string,
    image: string,
  };

  const initial_values = {
    Email: "",
    Name: "",
    UserName: "",
    Phone: "",
    CompanyId: "00000000-0000-0000-0000-000000000000",
    Roles: [],
    Address: "",
    Country: "",
    SecuritySuites: "",
    isViewOnly: false,
    ZoneId: "00000000-0000-0000-0000-000000000000",
    ProfileImage: "Profile Image",
    image: "",
  };

 
 /*const [values, setValues] = useState(initial_values);
  const handleInputChange = async (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };*/

  const[values, setValues]= useState(initial_values);
  const [error, setError]= useState(null);
  
  const [selectedImage, setSelectedImage] = useState(null);
  let userContext=useContext(UserContext);

  const handleImageChange = (e:any) => {
    const imageFile = e.target.files[0];
    setSelectedImage(imageFile);
  };

  const handleInputChange= (e:any)=>{
    setValues({...values,[e.target.name]: e.target.value})
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

 
  useEffect(() => {
    getRoles();
  }, []);


 
  const getRoles = async () => {
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );

      if (auth_token !== "") {
        axios
          .get(`${BASE_URL}/api/auth/roles`, {
            headers: { Authorization: `Bearer ${auth_token.token}` },
          })
          .then((res) => {
            setR(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const [roles, setRoles] = React.useState<string[]>([]);
  const [r, setR] = useState([]);

  const handleRoleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setRoles(
      // On autofill we get a the stringified value.
      value
    );
    values.Roles = value;
  };

  const GenerateRoles = (role: Role) => {
    const name: any = role.Name ? role.Name : "";
    const id: any = role.Id ? role.Id : "";

    return (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    );
  };

  const [isViewOnly, setIsViewOnly] = useState(false);

  const handleIsViewOnly = (e: any) => {
    if (isViewOnly) {
      setIsViewOnly(false);
      values.isViewOnly = false;
    } else {
      setIsViewOnly(true);
      values.isViewOnly = true;
    }
  };
  const [messageOnChange, setChangeMessage] = React.useState(false);
  const handleClose = () => {
    setChangeMessage(false);
  };

  const [message, setMessage] = useState("");
  const handleCreateAdmin = async(e: any) =>{
  
    e.preventDefault();
    const authorization_token = localStorage.getItem("auth_session");
    if (authorization_token) {
      const auth_token = JSON.parse(
        authorization_token != null ? authorization_token : ""
      );
      if (auth_token !== "") {
        try {const form = new FormData();

          form.append("Email", values.Email);
          form.append("Name", values.Name);
  
          form.append("userName", values.UserName);
          form.append("Phone", values.Phone);
          form.append("CompanyId", values.CompanyId);
          form.append("Roles",  new Blob(values?.Roles));
          form.append("Address", values.Address);
          form.append("Country", values.Country);
          form.append("SecuritySuites", values.SecuritySuites);
          form.append("isViewOnly", values.isViewOnly?"true":"false");
          form.append("ZoneId", values.ZoneId);
          form.append("ProfileImage", values.ProfileImage);

          const jsonString = JSON.stringify(selectedImage);

// Convert JSON string to Blob
const blob = new Blob([jsonString], { type: "application/json" });


          form.append("image",blob);
          const config = { Authorization: `Bearer ${auth_token.token}`,headers: { "Accept": 'application/json, text/plain, */*', "Content-Type": 'multipart/form-data' } };
          const response = await axios.post("https://test-adminapi.meetleelou.com/api/auth/user/new", form, config);
        
            console.log(response);
            /*userContext.setUser({
                ...userContext.user,
                currentUserName: response.data.Name,
                currentUserId: response.data.id
            })*/
         
         
      } catch (error:any) {
          // do something when error occurrs
         
          console.log(error?.response);
       
      }
  
  }

  }
    
        }


  return (
    <div>
      <Card >
        <CardContent>
          <Grid container  >
            
            <Grid item xs={12} p={2}>
            <Grid item xs={12} md={3} style={{backgroundColor:"#f6f6f6",padding:15, borderRadius:10}}>
              <Avatar  src={selectedImage?URL.createObjectURL(selectedImage):""} alt="Profile Image" style={{ height: '130px', width:"130px", marginBottom:10 }}></Avatar>
              <input type="file" accept="images/*" onChange={handleImageChange} />
            </Grid>
            </Grid>
            <Grid item xs={12} md={3} p={2} >
              <Grid item xs={12}>
                <TextField
                  id="full-name"
                  label="Full Name"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  onChange={handleInputChange}
                  value={values.Name}
                  name="Name"
                  fullWidth
                  required={true}
                />
              </Grid>
            
              <Grid item xs={12}>
                <TextField
                  id="username"
                  label="User Name"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  onChange={handleInputChange}
                  value={values.UserName}
                  name="UserName"
                  fullWidth
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="phone"
                  label="Phone"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  onChange={handleInputChange}
                  
                  value={values.Phone}
                  name="Phone"
                  fullWidth
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  onChange={handleInputChange}
                  value={values.Email}
                  name="Email"
                  required={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="country"
                  label="Country"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  fullWidth
                  name="Country"
                  onChange={handleInputChange}
                  value={values.Country}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address"
                  label="Address"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  fullWidth
                  name="Address"
                  onChange={handleInputChange}
                  
                  value={values.Address}
                />
              </Grid>
            
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12} md={3} p={2} >
                          <Grid item xs={12}>
                <TextField
                  id="company_id"
                  label="Company Id"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  value={values.CompanyId}
                  
                  name="CompanyId"
                  onChange={handleInputChange}
                  disabled
                  fullWidth
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="zone_id"
                  label="Zone Id"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  value={values.ZoneId}
                  
                  name="zoneId"
                  onChange={handleInputChange}
                  disabled
                  fullWidth
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="security_suits"
                  label="Security Suits"
                  variant="outlined"
                  color="warning"
                  sx={{ background: "rgba(0,0,0,0) !important", margin:2}}
                  fullWidth
                  name="SecuritySuites"
                  onChange={handleInputChange}
                  
                  value={values.SecuritySuites}
                />
              </Grid>
              <Grid item xs={12} py={2}>
                <InputLabel id="admin_roles" variant="outlined">Admin Roles *</InputLabel>
                <Select
                  required={true}
                  labelId="admin_roles"
                  id="admin_roles"
                  multiple
                  variant="outlined"
                  color="warning"
                  size="small"
                  fullWidth
                  value={roles}
                  onChange={handleRoleChange}
                  
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {r?.map(GenerateRoles)}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      required={true}
                      checked={isViewOnly}
                      onChange={handleIsViewOnly}
                     
                      name="isViewOnly"
                    />
                  }
                  label="View Only *"
                />
              </Grid>
            </Grid>
            <Grid />
            <Grid />

          </Grid>
          <Grid container>
            <Grid item xs={12} px={2} mb={6} justifyContent="center">
              <Button
                size="medium"
                onClick={handleCreateAdmin}
                color="warning"
                variant="contained"
              >
                Add Admin
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        open={messageOnChange}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
 }

function CreateAdmin() {
  return (
    <React.Fragment>
      <Helmet title="Create Admin" />

      <CreateForm />
    </React.Fragment>
  );
  }
export default CreateAdmin;
