import { date } from "faker";
import UsersList from "./UsersList";
import { useNavigate } from "react-router-dom";
import SignIn from "../auth/SignIn";
function DashboardUsers() {
  // CHECK USER AUTHENTICATION
  const navigate = useNavigate();

  const authorization_token = localStorage.getItem("auth_session");
  if (authorization_token) {
    console.log(authorization_token);
    const auth_token = JSON.parse(
      authorization_token != null ? authorization_token : ""
    );
    if (Date.parse(auth_token.expiration) < new Date().getTime() + 10) {
      return <UsersList />;
    } else {
      navigate("/auth/sign-in");
    }
  } else {
    navigate("/auth/sign-in");
  }
  return <UsersList />;
}

export default DashboardUsers;
