import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { grey, orange } from "@material-ui/core/colors";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { tableCellClasses } from "@material-ui/core/TableCell";
import DatePicker from "@material-ui/lab/DatePicker";
import Notification from "../components/Notification";
import axios from "axios";
import stringData from "../stringFile";
import {
  Avatar,
  Button,

  Chip,
  Checkbox,
  FormControl,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Dialog,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { ExpandMore as ExpandMoreIcon, NotificationsSharp as NotificationSettingIcon} from "@material-ui/icons";
import PushNotificationForm from "./PushNotificationForm";
import EmailNotificationForm from "./EmailNotificationForm";
const StyledTableCell = styled(TableCell)({
  padding: "10px",
});
const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;
const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;
const ExtraBigAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Card = styled(MuiCard)(spacing);
const ProductsChip = styled(Chip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function NotificationOptionsDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [openEmailForm, setOpenEmailForm] = React.useState(false);


  const [messageOnSuspend, setSuspendMessage] = React.useState(false);
const selectedRows = props?.selectedRows;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuspend = () => {
    setOpen(false);
    setSuspendMessage(true);
  };
  const [value, setValue] = React.useState<Date | null>(null);
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const { gilad, jason, antoine } = state;

  const [filterdate, setfilterdate] = useState<Date | null>(null);
  const handleResetFilter = () => {
    props.callback(props.data);
    setOpen(false);
  };
  const handleFilterApply = () => {
    const data: any = [];
    const dt = new Date(filterdate ? filterdate : new Date());

    props.data.map((key: any, value: any) => {
      const date = new Date(String(key.lastOnline));

      if (
        date.getDate() == dt.getDate() &&
        date.getMonth() == dt.getMonth() + 1 &&
        date.getFullYear() == dt.getFullYear()
      ) {
        data.push(key);
      }
    });
    props.callback(data);

    setOpen(false);
    setSuspendMessage(false);
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="warning"
        aria-haspopup="true"
        fullWidth={true}
        disabled={!selectedRows?.length}
      >
       Notification Control
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Notification Type"}</DialogTitle>
        <DialogContent>
        <div>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Push Notification</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <PushNotificationForm selectedRows={selectedRows} setOpen={setOpen}></PushNotificationForm>

            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Email Notification</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <EmailNotificationForm selectedRows={selectedRows}  setOpen={setOpen}></EmailNotificationForm>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>SMS Notification</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl.
                Integer sit amet egestas eros, vitae egestas augue. Duis vel est
                augue.
              </Typography>
            </AccordionDetails>
          </Accordion>

        </div>
        </DialogContent>
        {/* <DialogActions>
          <Grid
            container
            style={{ display: "flex", justifyContent: "flex-end" }}
            px={6}
          >
            <Button
              onClick={handleResetFilter}
              variant="contained"
              color="warning"
            >
              Clear Filter
            </Button>
            &nbsp; &nbsp;
            <Button onClick={handleFilterApply} variant="outlined">
              Apply & Show Results
            </Button>
          </Grid>
        </DialogActions> */}
      </Dialog>
      <Dialog
        open={messageOnSuspend}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Filter has been applied successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NotificationOptionsDialog;
