import * as React from "react";
import styled from "styled-components/macro";

import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
} from "@material-ui/core";

interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: string;
  href?: string;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};
  padding-top: 3px;
  padding-botton: 3px;

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden mdDown>
          <Grid container item xs={12} md={6}>
            <List disablePadding>
              <ListItemButton component="a" href="#">
                <ListItemText primary="Support" />
              </ListItemButton>
              <ListItemButton component="a" href="#">
                <ListItemText primary="Help Center" />
              </ListItemButton>
              <ListItemButton component="a" href="#">
                <ListItemText primary="Privacy" />
              </ListItemButton>
              <ListItemButton component="a" href="#">
                <ListItemText primary="Terms of Service" />
              </ListItemButton>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justifyContent="center">
          <List disablePadding>
            <ListItemButton>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Leelou`}
              />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
