import React, { useState, useContext, useMemo, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../contexts/AuthContext";
import { grey } from "@material-ui/core/colors";
import { Formik } from "formik";

import {
  Avatar,
  Grid,
  Alert as MuiAlert,
  IconButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField as MuiTextField,
  Typography,
  Fab as MuiFab,
  InputBase,
  Chip,
  CardContent,
  FormHelperText,
  InputLabel,
  MenuItem,
  FormControl,

  Box,
  Button as MuiButton,
  CircularProgress
} from "@material-ui/core";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, GridSelectionModel} from '@mui/x-data-grid';
import { GlobalContext } from "../../contexts/GlobalContext";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
  Visibility,
  VisibilityOff,
  Delete
} from "@material-ui/icons";
import { useMutation } from "@tanstack/react-query";
import { SelectChangeEvent } from "@material-ui/core/Select";
import * as Yup from "yup";
import { spacing, SpacingProps } from "@material-ui/system";
const Alert = styled(MuiAlert)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

// EmailVerified
// Device
// SOS Active
// Friends
// Guardians
// Dependants
// Pending Invites
// Last Online
// Status (edited) 


const Automation = ()=> {
  
const globalContext = useContext(GlobalContext);
// {id:"v",name:"Pending Invites"},

const parameters = [{id:"emailverified",name:"Email Verified",  values:["yes", "no"]},
{id:"device",name:"Device Type",values:['IOS', 'Android']},
{id:"sosactive",name:"Active SOS"},
{id:"friends",name:"Friends Count"},
{id:"guardians",name:"Guardians Count"},
{id:"dependants",name:"Dependants Count"},
{id:"lastonline",name:"Last Online", values:['oneday', 'oneweek' , 'morethanonemonth']},
{id:"status",name:"Login Status", values:['loggedin', 'loggedout']}];

const repetitionType = [{id:"once",name:"Once"},{id:"repetition",name:"Repetitive"}];
const repetitionValue = [{id:"H",name:"Hourly"},{id:"D",name:"Daily"}, {id:"M",name:"Monthly"}];
const notificationType = [{id:"fcm",name:"Push Notify"},{id:"email",name:"Email"}, {"id":"sms", name:"SMS"}];

interface Schedular {
  "id": number,
  "schedular_type": string,
  "schedular_value": string,
  "repetition": string,
  "repetition_type": string,
  "title": string,
  "description": string
  "notification_type":string,
}

//this is

const [automationList, setAutomationList] = useState<Schedular[]>([]);
const [isLoading, setisLoading] = useState(true);
const [selectedRepetitionBy, setRepetitionBy] = useState("");

const [automationData, setAutomationData] = useState({
  "schedular_type": "",
  "schedular_value": "",
  "repetition": "",
  "repetition_type": "",
  "title": "",
  "description": "",
  "notification_type":""
})

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Id', width: 150, disableColumnMenu:true, },
  { field: 'notification_type', headerName: 'N-Type', width: 150, disableColumnMenu:true, 
},
  { field: 'schedular_type', headerName: 'Type', width: 150, disableColumnMenu:true, 
  },
  { field: 'schedular_value', headerName: 'Value', width: 150, disableColumnMenu:true, 
  },
  { field: 'repetition', headerName: 'Repetition', width: 150, disableColumnMenu:true, 
  },
  { field: 'repetition_type', headerName: 'Repetition Type', width: 150, disableColumnMenu:true, 
},
{ field: 'title', headerName: 'Title', width: 350, disableColumnMenu:true, 
},
{ field: 'description', headerName: 'Description', width: 350, disableColumnMenu:true, 
},
{
  field: 'Action',
  headerName: 'Action',
  width: 120,
  sortable: false,
  disableColumnMenu:true,
  renderCell: (params: GridRenderCellParams) => (
    <IconButton size="large" color="warning"
    onClick={()=> globalContext?.setDisplayDialog({
      open: true,
      title: "Delete Feedback",
      description: "Are you sure you want to delete this automation schedular?",
      buttons: [{ name: "Yes", onClick: () => deleteAutomation({id: params?.row?.id}) }],
    }) }
    >
      <Delete color={'error'}/>
    </IconButton>
  ),
},
];
const validationSchema = Yup.object().shape({
  schedular_type: Yup.string().required("Required"),
  schedular_value: Yup.string().notRequired(),
  repetition: Yup.string().required("Required"),
  repetition_type: Yup.string().required("Required"),
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

useEffect(() => {
  getAutomationList();
},[])


const getAutomationList = async () => {

  const authorization_token = localStorage.getItem("auth_session");
  //console.log(authorization_token);
  if (authorization_token) {
    const auth_token = JSON.parse(
      authorization_token != null ? authorization_token : ""
    );

    if (auth_token !== "") {
      await axios
        .get(
          `https://test-adminapi.meetleelou.com/api/Schedular/schedular-setting`,
          { headers: { Authorization: `Bearer ${auth_token?.token}` } }
        )
        .then((res) => {
        if(res?.data?.Status){
          setAutomationList(res?.data?.Data)
        } 
        setisLoading(false)
        })
        .catch((error:any) => {
          console.log(error);
          setisLoading(false)

        });
    }
  } else {
//can you include the navigation screen
  }
};

const[creatingAUtomation, setCreatingAutomation] = useState(false);

const deleteAutomation = async (data:any) => {
  console.log("ID", data?.id)

  const authorization_token = localStorage.getItem("auth_session");
  if (authorization_token) {
    const auth_token = JSON.parse(
      authorization_token != null ? authorization_token : ""
    );

    if (auth_token !== "") {
      await axios
        .delete(
          `https://test-adminapi.meetleelou.com/api/Schedular/schedular-setting?id=${data?.id}`,
          { headers: { Authorization: `Bearer ${auth_token?.token}` } }
        )
        .then((res) => {
          if(res?.data?.Status){
          globalContext?.setDisplayQuickAlert({
            open: true,
            message: "Successfully deleted!",
          });  
          getAutomationList();
        }else{
          throw "Error";
        }
              })
        .catch((error) => {

          console.log(error)
          globalContext?.setDisplayQuickAlert({
            open: true,
            message: "Error occurred while deleting schedualr event.",
          });        });
    }
  } else {
//can you include the navigation screen
  }
};

const createAutomation = async (data:any) => {

  const authorization_token = localStorage.getItem("auth_session");
  setCreatingAutomation(true)
  //console.log(authorization_token);
  if (authorization_token) {
    const auth_token = JSON.parse(
      authorization_token != null ? authorization_token : ""
    );

    if (auth_token !== "") {
      await axios
        .post(
          `https://test-adminapi.meetleelou.com/api/Schedular/schedular-setting`,data,
          { headers: { Authorization: `Bearer ${auth_token?.token}` } }
        )
        .then((res) => {
          console.log(res.data)
          setCreatingAutomation(false)
          globalContext?.setDisplayQuickAlert({
            open: true,
            message: "Successfully scheduled!",
          });  
          getAutomationList();
              })
        .catch((error) => {
          setCreatingAutomation(false)

          console.log(error)
          globalContext?.setDisplayQuickAlert({
            open: true,
            message: "Error occurred while creating schedualr event.",
          });        });
    }
  } else {
//can you include the navigation screen
  }
};


  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      setSubmitting(true);
      const data = {...values, repetition:`${values?.repetition} ${selectedRepetitionBy}`}
      createAutomation(data);
    } catch (error: any) {
      console.log(error)
      globalContext?.setDisplayQuickAlert({
        open: true,
        message: "Error occurred creating schedular",
      });  
      
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  
  
  return (
    <React.Fragment>
    <Helmet title="Automation" />
    {/* <BigAvatar
                alt={String(key?.FirstName)}
                src={
                  "http://meetleeloudashboard.com.s3-website-ap-southeast-2.amazonaws.com/" +
                  String(key?.Avatar)
                }
              /> */}
    <Grid container style={{ height:"100%"}} spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        style={{ backgroundColor:"#fff"}} 
      >
        <Grid container spacing={2} px={2} style={{height:'5%'}}>
          <Grid item xs={8}>
            {/* <SearchField oc={handleChange} value={SearchTerm} /> */}
          </Grid>
          <Grid item xs={2}>
            {/* <FilterDialog data={DefaultUsers} callback={setUsers} /> */}
          </Grid>
          <Grid item xs={2} >
            {/* <NotificationOptionsDialog data={DefaultUsers} callback={setUsers} selectedRows={selectedRows}/> */}
        
          </Grid>
        </Grid>

        <Grid container spacing={0} px={2} style={{height:'95%'}}>
        <DataGrid
      rows={automationList}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.id}
      // checkboxSelection
      // disableSelectionOnClick
      onRowClick={(params:any)=>console.log(params?.row)}
      // onSelectionModelChange={handleSelectionChange}
      
    />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        sx={{
          flexDirection: "row",
          overflowY: "auto", // Enable vertical scrolling
          height: "100%", // Take full height of the parent
        }}
        spacing={2}
      >
        <Grid item xs={12}>
        <Card sx={{ width: "100%" }} p={10}>
        <Formik
      initialValues={automationData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <> 
          {status && status.sent (
          <Alert severity="success" my={3}>
          Notificaiton successfully scheduled!
          </Alert>
        )}

          <form onSubmit={handleSubmit}>
                <Grid container >
                <Grid item xs={12} my={2}>
                <Typography variant="h6" my={2}>
              Parameter
          </Typography>
                <FormControl>
            <InputLabel id="schedular_type">Parameter</InputLabel>
            <Select
              labelId="schedular_type"
              id="schedular_type"
              name={"schedular_type"}
              error={Boolean(touched.schedular_type && errors.schedular_type)}
              value={values?.schedular_type}
              style={{width:150, marginRight:5,}}
              onChange={handleChange}
            >
              {parameters.map((param) =>  <MenuItem value={param?.id}>{param?.name}</MenuItem>)}

            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="schedular_value">Value</InputLabel>
            <Select
              labelId="schedular_value"
              id="schedular_value"
              name={"schedular_value"}
              value={values?.schedular_value}
              style={{width:150, marginRight:5,}}
              onChange={handleChange}
            >
              { parameters.find((item) => item.id === values?.schedular_type)?.values ?.map((value) =><MenuItem value={value}>{value.toUpperCase()}</MenuItem>)}
            </Select>
          </FormControl>
        
          
          </Grid>

          <Grid item xs={12} my={2}>
          <Typography variant="h6" my={2}>
              Timer
          </Typography>
          <FormControl>
            <InputLabel id="repetition_type">Repetition Type</InputLabel>
            <Select
              labelId="repetition_type"
              id="repetition_type"
              name="repetition_type"
              error={Boolean(touched.repetition_type && errors.repetition_type)}

              value={values?.repetition_type}
              style={{width:150, marginRight:5,}}
              onChange={handleChange}
            >
              {repetitionType.map((param) =>  <MenuItem value={param?.id}>{param?.name}</MenuItem>)}

            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="repetitionBy">Repetition By</InputLabel>
            <Select
              labelId="repetitionBy"
              id="repetitionBy"
              name="repetitionBy"

              value={selectedRepetitionBy}
              style={{width:150, marginRight:5,}}
              onChange={(e)=>setRepetitionBy(e.target.value)}
            >
              {repetitionValue.map((param) =>  <MenuItem value={param?.id}>{param?.name}</MenuItem>)}

            </Select>
          </FormControl>
          <FormControl>
            <TextField
                      name="repetition"
                      label="Repetition Value"
                      value={values.repetition}
                      error={Boolean(touched.repetition && errors.repetition)}
                      helperText={touched.repetition && errors.repetition}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      style={{width:150, marginRight:5,}}
                      inputProps={{
                        maxLength: 40,
                      }}
                    />


          </FormControl>
          </Grid>
          
          <Grid item xs={12} my={2}>
          <Typography variant="h6" my={2}>
               Notificaiton Details
          </Typography>
          <FormControl style={{ marginBottom:10}}>
            <InputLabel id="notification_type">Notification Type</InputLabel>
            <Select
              labelId="notification_type"
              id="notification_type"
              name={"notification_type"}
              value={values?.notification_type}
              style={{width:150, marginRight:5,}}
              onChange={handleChange}
            >
              {  notificationType?.map((value) =><MenuItem value={value?.id}>{value.name}</MenuItem>)}
            </Select>
          </FormControl>
          
                <FormControl fullWidth>
                <TextField
                      name="title"
                      label="Title"
                      value={values.title}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      style={{width:"100%"}}
                      inputProps={{
                        maxLength: 40,
                      }}
                    />
            <FormHelperText>Maximum 40 characters allowed.</FormHelperText>
          </FormControl>
         

              
<FormControl fullWidth>
          <TextField
                      name="description"
                      label="Description"
                      value={values.description}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      multiline={true}
                      rows={4}
                      my={2}
                      style={{width:"100%"}}
                      inputProps={{
                        maxLength: 240
                      }}
                    />
            <FormHelperText>Maximum 240 characters allowed.</FormHelperText>
          </FormControl>
                  
                    </Grid>
                </Grid>

           

                <Button
                  type="submit"
                  variant="contained"
                  color="warning"
                  my={5}
                  // disabled={isSubmitting }
                  size={"large"}
                >
                {creatingAUtomation?<CircularProgress color="inherit" size={24} />:"Schedule Automation"}
                </Button>
              </form>  </>   )}
              </Formik>

          </Card>
        

        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>
  );
}

export default Automation;
