import React, {useEffect, useState} from "react";
import styled, { withTheme, ThemeProps } from "styled-components/macro";
import { MoreVertical } from "react-feather";
import { VectorMap } from "react-jvectormap";
import { Button as MuiButton, Menu, MenuItem } from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";

import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  IconButton,
  Theme,
  Grid,
  LinearProgress as MuiLinearProgress,
  linearProgressClasses,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import axios from "axios";
import "../../../vendor/jvectormap.css";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { CountryList } from "./CountryCode";
const MapContainer = styled.div`
  height: 344px;
`;

const Card = styled(MuiCard)(spacing);
const BorderLinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgba(0, 0, 255, 1)",
  },
}));
const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-top: 0;
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;
const Button = styled(MuiButton)(spacing);
const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 100px;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;
const LinearProgress = styled(MuiLinearProgress)(spacing);


function WorldMap(props: { theme: ThemeProps<Theme> & { palette: any } }) {
  const CountryData = CountryList.countries;
  const[userCountData, setUserCountData] = useState([]);


useEffect(() => {
  fetchRegisteredUserCountByCountry();
}, []);


function generateRandomBlueRGBA(alpha = 0.5) {
  // Generate random values for red and green channels (0-255)
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  
  // Fixed blue channel to 255 (full blue)
  const blue = 255;
  
  // Validate and clamp alpha value between 0 and 1
  const clampedAlpha = Math.max(0, Math.min(1, alpha));

  // Construct the RGBA color string
  const fillColor = `rgba(${red}, ${green}, ${blue}, ${clampedAlpha})`;
  const StrokeColor = `rgba(${red}, ${green}, ${blue}, ${1})`;

  return {fillColor,StrokeColor };
}


const getFlagEmoji = (countryCode:string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char:any) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const fetchRegisteredUserCountByCountry = async () => {
  try {
    const response = await axios.get(
      "https://test-adminapi.meetleelou.com/api/Dashboard/user-count-country"
    );

    if(response?.data?.Status){
      const CountData = response?.data?.Data;

      const countryDataMap = new Map();
      CountryData.forEach(country => {
        countryDataMap.set(country.country, country);
      });

      const mappedData = CountData.map((countObj:any) => {
        const countryCode = countObj.CountryCode;
        const countryObj = countryDataMap.get(countryCode);
        const {fillColor,StrokeColor } = generateRandomBlueRGBA();
        if (countryObj) {
          return {
            name: `${getFlagEmoji(countryObj.countryIso)} ${countryObj.name.eng} - ${parseInt(countObj.Count)}`,
            value: parseInt(countObj.Count),
            latLng: countryObj.coordinates,
            countryIso:countryObj.countryIso,
            style: { fill: fillColor, stroke: StrokeColor, r: parseInt(countObj.Count) },
          };
        } else {
          // Handle cases where CountryCode from CountData does not match any country in CountryData
          return {
            name: `${getFlagEmoji(countryObj.countryIso)} ${countryObj.name.eng} - ${parseInt(countObj.Count)}`,
            value: parseInt(countObj.Count),
            latLng: null, // or handle as per your requirement
            countryIso:countryObj.countryIso,
            style: { fill: fillColor, stroke: StrokeColor, r: parseInt(countObj.Count) },
          };
        }
      });
      setUserCountData(mappedData);
    }
  } catch (error) {
    console.log(error);
  }
};



const getOptions = () => {
return {
    map: "world_mill",
    regionStyle: {
      initial: {
        fill:
          props.theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.3)"
            : "#e3eaef",
      },
    },
    series: {
      markers: [
        {
          attribute: "r",
          scale: [5, 20],
          values: [60, 6, 54, 100],
          normalizeFunction: "polynomial",
        },
      ],
    },
    backgroundColor: "transparent",
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    markerStyle: {
      initial: {
        r: 9,
        fill: "#ffff",
        stroke: "#383f47",
        "stroke-width": 3,
        "stroke-opacity": 1,
      },
      hover: {
        stroke: props.theme.palette.secondary.main,
        "stroke-width": 0,
        "fill-opacity": 1,
      },
    },
    zoomOnScroll: false,
    markers: userCountData,
  };
}



  return (
    <Card mb={6}>
      <CardHeader
        // action={<SmallButton size="small">View Full Report</SmallButton>}
        title="User Registration By Country"
      />
      <CardContent>
        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              <MapContainer>
                <VectorMap {...getOptions()} />
              </MapContainer>
            </Grid>
          </Grid>
          {userCountData?.map((countData:any) =>    <><Grid container>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              {countData?.name}{" "}
            </Grid>
            {/* <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
              color={grey[500]}
            >
            {countData?.value}
            </Grid> */}
          </Grid>
          <Grid container mb={3}>
            <Grid item xs={12}>
              <BorderLinearProgress variant="determinate" value= {countData?.value/10}  />
            </Grid>
          </Grid>
          </>)}
          
        
        </Grid>
      </CardContent>
    </Card>
  );
}

export default withTheme(WorldMap);
