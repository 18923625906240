export const CountryList = {
  countries: [
    {
      country: "93",
      countryIso: "AF",
      priority: 0,
      name: {
        eng: "Afghanistan",
      },
      coordinates: [33.8364489128, 66.0087623043],
    },
    {
      country: "358",
      countryIso: "AX",
      priority: 999,
      name: {
        eng: "Åland Islands",
      },
      coordinates: [],
    },
    {
      country: "355",
      countryIso: "AL",
      priority: 0,
      name: {
        eng: "Albania",
      },
      coordinates: [41.1423824499, 20.0536337871],
    },
    {
      country: "213",
      countryIso: "DZ",
      priority: 0,
      name: {
        eng: "Algeria",
      },
      coordinates: [28.1489523417, 2.65318018402],
    },
    {
      country: "1",
      countryIso: "AS",
      priority: 999,
      name: {
        eng: "American Samoa",
      },
      coordinates: [],
    },
    {
      country: "376",
      countryIso: "AD",
      priority: 0,
      name: {
        eng: "Andorra",
      },
      coordinates: [42.5423528314, 1.56130102941],
    },
    {
      country: "244",
      countryIso: "AO",
      priority: 0,
      name: {
        eng: "Angola",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "AI",
      priority: 999,
      name: {
        eng: "Anguilla",
      },
      coordinates: [18.224869546, -63.0600473989],
    },
    {
      country: "672",
      countryIso: "AQ",
      priority: 999,
      name: {
        eng: "Antarctica",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "AG",
      priority: 999,
      name: {
        eng: "Antigua and Barbuda",
      },
      coordinates: [],
    },
    {
      country: "54",
      countryIso: "AR",
      priority: 0,
      name: {
        eng: "Argentina",
      },
      coordinates: [],
    },
    {
      country: "374",
      countryIso: "AM",
      priority: 0,
      name: {
        eng: "Armenia",
      },
      coordinates: [],
    },
    {
      country: "297",
      countryIso: "AW",
      priority: 0,
      name: {
        eng: "Aruba",
      },
      coordinates: [],
    },
    {
      country: "61",
      countryIso: "AU",
      priority: 0,
      name: {
        eng: "Australia",
      },
      coordinates: [-25.7323831126, 134.491117773],
    },
    {
      country: "43",
      countryIso: "AT",
      priority: 0,
      name: {
        eng: "Austria",
      },
      coordinates: [],
    },
    {
      country: "994",
      countryIso: "AZ",
      priority: 0,
      name: {
        eng: "Azerbaijan",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "BS",
      priority: 999,
      name: {
        eng: "Bahamas",
      },
      coordinates: [],
    },
    {
      country: "973",
      countryIso: "BH",
      priority: 0,
      name: {
        eng: "Bahrain",
      },
      coordinates: [],
    },
    {
      country: "880",
      countryIso: "BD",
      priority: 0,
      name: {
        eng: "Bangladesh",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "BB",
      priority: 999,
      name: {
        eng: "Barbados",
      },
      coordinates: [],
    },
    {
      country: "375",
      countryIso: "BY",
      priority: 0,
      name: {
        eng: "Belarus",
      },
      coordinates: [],
    },
    {
      country: "32",
      countryIso: "BE",
      priority: 0,
      name: {
        eng: "Belgium",
      },
      coordinates: [],
    },
    {
      country: "501",
      countryIso: "BZ",
      priority: 0,
      name: {
        eng: "Belize",
      },
      coordinates: [],
    },
    {
      country: "229",
      countryIso: "BJ",
      priority: 0,
      name: {
        eng: "Benin",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "BM",
      priority: 999,
      name: {
        eng: "Bermuda",
      },
      coordinates: [],
    },
    {
      country: "975",
      countryIso: "BT",
      priority: 0,
      name: {
        eng: "Bhutan",
      },
      coordinates: [],
    },
    {
      country: "591",
      countryIso: "BO",
      priority: 0,
      name: {
        eng: "Bolivia",
      },
      coordinates: [],
    },
    {
      country: "599",
      countryIso: "BQ",
      priority: 999,
      name: {
        eng: "Bonaire",
      },
      coordinates: [],
    },
    {
      country: "387",
      countryIso: "BA",
      priority: 0,
      name: {
        eng: "Bosnia and Herzegovina",
      },
      coordinates: [],
    },
    {
      country: "267",
      countryIso: "BW",
      priority: 0,
      name: {
        eng: "Botswana",
      },
      coordinates: [],
    },
    {
      country: "47",
      countryIso: "BV",
      priority: 999,
      name: {
        eng: "Bouvet Island",
      },
      coordinates: [],
    },
    {
      country: "55",
      countryIso: "BR",
      priority: 0,
      name: {
        eng: "Brazil",
      },
      coordinates: [],
    },
    {
      country: "246",
      countryIso: "IO",
      priority: 0,
      name: {
        eng: "British Indian Ocean Territory",
      },
      coordinates: [],
    },
    {
      country: "673",
      countryIso: "BN",
      priority: 0,
      name: {
        eng: "Brunei",
      },
      coordinates: [],
    },
    {
      country: "359",
      countryIso: "BG",
      priority: 0,
      name: {
        eng: "Bulgaria",
      },
      coordinates: [],
    },
    {
      country: "226",
      countryIso: "BF",
      priority: 0,
      name: {
        eng: "Burkina Faso",
      },
      coordinates: [],
    },
    {
      country: "257",
      countryIso: "BI",
      priority: 0,
      name: {
        eng: "Burundi",
      },
      coordinates: [],
    },
    {
      country: "855",
      countryIso: "KH",
      priority: 0,
      name: {
        eng: "Cambodia",
      },
      coordinates: [],
    },
    {
      country: "237",
      countryIso: "CM",
      priority: 0,
      name: {
        eng: "Cameroon",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "CA",
      priority: 999,
      name: {
        eng: "Canada",
      },
      coordinates: [],
    },
    {
      country: "238",
      countryIso: "CV",
      priority: 0,
      name: {
        eng: "Cape Verde",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "KY",
      priority: 999,
      name: {
        eng: "Cayman Islands",
      },
      coordinates: [],
    },
    {
      country: "236",
      countryIso: "CF",
      priority: 0,
      name: {
        eng: "Central African Republic",
      },
      coordinates: [],
    },
    {
      country: "235",
      countryIso: "TD",
      priority: 0,
      name: {
        eng: "Chad",
      },
      coordinates: [],
    },
    {
      country: "56",
      countryIso: "CL",
      priority: 0,
      name: {
        eng: "Chile",
      },
      coordinates: [],
    },
    {
      country: "86",
      countryIso: "CN",
      priority: 0,
      name: {
        eng: "China",
      },
      coordinates: [],
    },
    {
      country: "57",
      countryIso: "CO",
      priority: 0,
      name: {
        eng: "Colombia",
      },
      coordinates: [],
    },
    {
      country: "269",
      countryIso: "KM",
      priority: 0,
      name: {
        eng: "Comoros",
      },
      coordinates: [],
    },
    {
      country: "682",
      countryIso: "CK",
      priority: 0,
      name: {
        eng: "Cook Islands",
      },
      coordinates: [],
    },
    {
      country: "506",
      countryIso: "CR",
      priority: 0,
      name: {
        eng: "Costa Rica",
      },
      coordinates: [],
    },
    {
      country: "385",
      countryIso: "HR",
      priority: 0,
      name: {
        eng: "Croatia",
      },
      coordinates: [],
    },
    {
      country: "53",
      countryIso: "CU",
      priority: 0,
      name: {
        eng: "Cuba",
      },
      coordinates: [],
    },
    {
      country: "599",
      countryIso: "CW",
      priority: 0,
      name: {
        eng: "Curaçao",
      },
      coordinates: [],
    },
    {
      country: "357",
      countryIso: "CY",
      priority: 0,
      name: {
        eng: "Cyprus",
      },
      coordinates: [],
    },
    {
      country: "420",
      countryIso: "CZ",
      priority: 0,
      name: {
        eng: "Czech Republic",
      },
      coordinates: [],
    },
    {
      country: "243",
      countryIso: "CD",
      priority: 0,
      name: {
        eng: "Democratic Republic of the Congo",
      },
      coordinates: [],
    },
    {
      country: "45",
      countryIso: "DK",
      priority: 0,
      name: {
        eng: "Denmark",
      },
      coordinates: [],
    },
    {
      country: "253",
      countryIso: "DJ",
      priority: 0,
      name: {
        eng: "Djibouti",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "DM",
      priority: 999,
      name: {
        eng: "Dominica",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "DO",
      priority: 999,
      name: {
        eng: "Dominican Republic",
      },
      coordinates: [],
    },
    {
      country: "593",
      countryIso: "EC",
      priority: 0,
      name: {
        eng: "Ecuador",
      },
      coordinates: [],
    },
    {
      country: "20",
      countryIso: "EG",
      priority: 0,
      name: {
        eng: "Egypt",
      },
      coordinates: [],
    },
    {
      country: "503",
      countryIso: "SV",
      priority: 0,
      name: {
        eng: "El Salvador",
      },
      coordinates: [],
    },
    {
      country: "240",
      countryIso: "GQ",
      priority: 0,
      name: {
        eng: "Equatorial Guinea",
      },
      coordinates: [],
    },
    {
      country: "291",
      countryIso: "ER",
      priority: 0,
      name: {
        eng: "Eritrea",
      },
      coordinates: [],
    },
    {
      country: "372",
      countryIso: "EE",
      priority: 0,
      name: {
        eng: "Estonia",
      },
      coordinates: [],
    },
    {
      country: "251",
      countryIso: "ET",
      priority: 0,
      name: {
        eng: "Ethiopia",
      },
      coordinates: [],
    },
    {
      country: "500",
      countryIso: "FK",
      priority: 0,
      name: {
        eng: "Falkland Islands",
      },
      coordinates: [],
    },
    {
      country: "298",
      countryIso: "FO",
      priority: 0,
      name: {
        eng: "Faroe Islands",
      },
      coordinates: [],
    },
    {
      country: "679",
      countryIso: "FJ",
      priority: 0,
      name: {
        eng: "Fiji",
      },
      coordinates: [],
    },
    {
      country: "358",
      countryIso: "FI",
      priority: 0,
      name: {
        eng: "Finland",
      },
      coordinates: [],
    },
    {
      country: "33",
      countryIso: "FR",
      priority: 0,
      name: {
        eng: "France",
      },
      coordinates: [],
    },
    {
      country: "594",
      countryIso: "GF",
      priority: 0,
      name: {
        eng: "French Guiana",
      },
      coordinates: [],
    },
    {
      country: "689",
      countryIso: "PF",
      priority: 0,
      name: {
        eng: "French Polynesia",
      },
      coordinates: [],
    },
    {
      country: "262",
      countryIso: "TF",
      priority: 999,
      name: {
        eng: "French Southern and Antarctic Lands",
      },
      coordinates: [],
    },
    {
      country: "241",
      countryIso: "GA",
      priority: 0,
      name: {
        eng: "Gabon",
      },
      coordinates: [],
    },
    {
      country: "220",
      countryIso: "GM",
      priority: 0,
      name: {
        eng: "Gambia",
      },
      coordinates: [],
    },
    {
      country: "995",
      countryIso: "GE",
      priority: 0,
      name: {
        eng: "Georgia",
      },
      coordinates: [],
    },
    {
      country: "49",
      countryIso: "DE",
      priority: 0,
      name: {
        eng: "Germany",
      },
      coordinates: [],
    },
    {
      country: "233",
      countryIso: "GH",
      priority: 0,
      name: {
        eng: "Ghana",
      },
      coordinates: [],
    },
    {
      country: "350",
      countryIso: "GI",
      priority: 0,
      name: {
        eng: "Gibraltar",
      },
      coordinates: [],
    },
    {
      country: "30",
      countryIso: "GR",
      priority: 0,
      name: {
        eng: "Greece",
      },
      coordinates: [],
    },
    {
      country: "299",
      countryIso: "GL",
      priority: 0,
      name: {
        eng: "Greenland",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "GD",
      priority: 999,
      name: {
        eng: "Grenada",
      },
      coordinates: [],
    },
    {
      country: "590",
      countryIso: "GP",
      priority: 0,
      name: {
        eng: "Guadeloupe",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "GU",
      priority: 999,
      name: {
        eng: "Guam",
      },
      coordinates: [],
    },
    {
      country: "502",
      countryIso: "GT",
      priority: 0,
      name: {
        eng: "Guatemala",
      },
      coordinates: [],
    },
    {
      country: "44",
      countryIso: "GG",
      priority: 999,
      name: {
        eng: "Guernsey",
      },
      coordinates: [],
    },
    {
      country: "224",
      countryIso: "GN",
      priority: 0,
      name: {
        eng: "Guinea",
      },
      coordinates: [],
    },
    {
      country: "245",
      countryIso: "GW",
      priority: 0,
      name: {
        eng: "Guinea-Bissau",
      },
      coordinates: [],
    },
    {
      country: "592",
      countryIso: "GY",
      priority: 0,
      name: {
        eng: "Guyana",
      },
      coordinates: [],
    },
    {
      country: "509",
      countryIso: "HT",
      priority: 0,
      name: {
        eng: "Haiti",
      },
      coordinates: [],
    },
    {
      country: "672",
      countryIso: "HM",
      priority: 999,
      name: {
        eng: "Heard Island and McDonald Islands",
      },
      coordinates: [],
    },
    {
      country: "379",
      countryIso: "VA",
      priority: 0,
      name: {
        eng: "Holy See",
      },
      coordinates: [],
    },
    {
      country: "504",
      countryIso: "HN",
      priority: 0,
      name: {
        eng: "Honduras",
      },
      coordinates: [],
    },
    {
      country: "852",
      countryIso: "HK",
      priority: 0,
      name: {
        eng: "Hong Kong",
      },
      coordinates: [],
    },
    {
      country: "36",
      countryIso: "HU",
      priority: 0,
      name: {
        eng: "Hungary",
      },
      coordinates: [],
    },
    {
      country: "354",
      countryIso: "IS",
      priority: 0,
      name: {
        eng: "Iceland",
      },
      coordinates: [],
    },
    {
      country: "91",
      countryIso: "IN",
      priority: 0,
      name: {
        eng: "India",
      },
      coordinates: [],
    },
    {
      country: "62",
      countryIso: "ID",
      priority: 0,
      name: {
        eng: "Indonesia",
      },
      coordinates: [],
    },
    {
      country: "98",
      countryIso: "IR",
      priority: 0,
      name: {
        eng: "Iran",
      },
      coordinates: [],
    },
    {
      country: "964",
      countryIso: "IQ",
      priority: 0,
      name: {
        eng: "Iraq",
      },
      coordinates: [],
    },
    {
      country: "353",
      countryIso: "IE",
      priority: 0,
      name: {
        eng: "Ireland",
      },
      coordinates: [],
    },
    {
      country: "44",
      countryIso: "IM",
      priority: 999,
      name: {
        eng: "Isle of Man",
      },
      coordinates: [],
    },
    {
      country: "972",
      countryIso: "IL",
      priority: 0,
      name: {
        eng: "Israel",
      },
      coordinates: [],
    },
    {
      country: "39",
      countryIso: "IT",
      priority: 0,
      name: {
        eng: "Italy",
      },
      coordinates: [],
    },
    {
      country: "225",
      countryIso: "CI",
      priority: 0,
      name: {
        eng: "Ivory Coast",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "JM",
      priority: 999,
      name: {
        eng: "Jamaica",
      },
      coordinates: [],
    },
    {
      country: "81",
      countryIso: "JP",
      priority: 0,
      name: {
        eng: "Japan",
      },
      coordinates: [],
    },
    {
      country: "44",
      countryIso: "JE",
      priority: 999,
      name: {
        eng: "Jersey",
      },
      coordinates: [],
    },
    {
      country: "962",
      countryIso: "JO",
      priority: 0,
      name: {
        eng: "Jordan",
      },
      coordinates: [],
    },
    {
      country: "7",
      countryIso: "KZ",
      priority: 999,
      name: {
        eng: "Kazakhstan",
      },
      coordinates: [],
    },
    {
      country: "254",
      countryIso: "KE",
      priority: 0,
      name: {
        eng: "Kenya",
      },
      coordinates: [],
    },
    {
      country: "686",
      countryIso: "KI",
      priority: 0,
      name: {
        eng: "Kiribati",
      },
      coordinates: [],
    },
    {
      country: "383",
      countryIso: "XK",
      priority: 0,
      name: {
        eng: "Kosovo",
      },
      coordinates: [],
    },
    {
      country: "965",
      countryIso: "KW",
      priority: 0,
      name: {
        eng: "Kuwait",
      },
      coordinates: [],
    },
    {
      country: "996",
      countryIso: "KG",
      priority: 0,
      name: {
        eng: "Kyrgyzstan",
      },
      coordinates: [],
    },
    {
      country: "856",
      countryIso: "LA",
      priority: 0,
      name: {
        eng: "Laos",
      },
      coordinates: [],
    },
    {
      country: "371",
      countryIso: "LV",
      priority: 0,
      name: {
        eng: "Latvia",
      },
      coordinates: [],
    },
    {
      country: "961",
      countryIso: "LB",
      priority: 0,
      name: {
        eng: "Lebanon",
      },
      coordinates: [],
    },
    {
      country: "266",
      countryIso: "LS",
      priority: 0,
      name: {
        eng: "Lesotho",
      },
      coordinates: [],
    },
    {
      country: "231",
      countryIso: "LR",
      priority: 0,
      name: {
        eng: "Liberia",
      },
      coordinates: [],
    },
    {
      country: "218",
      countryIso: "LY",
      priority: 0,
      name: {
        eng: "Libya",
      },
      coordinates: [],
    },
    {
      country: "423",
      countryIso: "LI",
      priority: 0,
      name: {
        eng: "Liechtenstein",
      },
      coordinates: [],
    },
    {
      country: "370",
      countryIso: "LT",
      priority: 0,
      name: {
        eng: "Lithuania",
      },
      coordinates: [],
    },
    {
      country: "352",
      countryIso: "LU",
      priority: 0,
      name: {
        eng: "Luxembourg",
      },
      coordinates: [],
    },
    {
      country: "853",
      countryIso: "MO",
      priority: 0,
      name: {
        eng: "Macau",
      },
      coordinates: [],
    },
    {
      country: "389",
      countryIso: "MK",
      priority: 0,
      name: {
        eng: "Macedonia",
      },
      coordinates: [],
    },
    {
      country: "261",
      countryIso: "MG",
      priority: 0,
      name: {
        eng: "Madagascar",
      },
      coordinates: [],
    },
    {
      country: "265",
      countryIso: "MW",
      priority: 0,
      name: {
        eng: "Malawi",
      },
      coordinates: [],
    },
    {
      country: "60",
      countryIso: "MY",
      priority: 0,
      name: {
        eng: "Malaysia",
      },
      coordinates: [],
    },
    {
      country: "960",
      countryIso: "MV",
      priority: 0,
      name: {
        eng: "Maldives",
      },
      coordinates: [],
    },
    {
      country: "223",
      countryIso: "ML",
      priority: 0,
      name: {
        eng: "Mali",
      },
      coordinates: [],
    },
    {
      country: "356",
      countryIso: "MT",
      priority: 0,
      name: {
        eng: "Malta",
      },
      coordinates: [],
    },
    {
      country: "692",
      countryIso: "MH",
      priority: 0,
      name: {
        eng: "Marshall Islands",
      },
      coordinates: [],
    },
    {
      country: "596",
      countryIso: "MQ",
      priority: 0,
      name: {
        eng: "Martinique",
      },
      coordinates: [],
    },
    {
      country: "222",
      countryIso: "MR",
      priority: 0,
      name: {
        eng: "Mauritania",
      },
      coordinates: [],
    },
    {
      country: "230",
      countryIso: "MU",
      priority: 0,
      name: {
        eng: "Mauritius",
      },
      coordinates: [],
    },
    {
      country: "262",
      countryIso: "YT",
      priority: 999,
      name: {
        eng: "Mayotte",
      },
      coordinates: [],
    },
    {
      country: "52",
      countryIso: "MX",
      priority: 0,
      name: {
        eng: "Mexico",
      },
      coordinates: [],
    },
    {
      country: "691",
      countryIso: "FM",
      priority: 0,
      name: {
        eng: "Micronesia",
      },
      coordinates: [],
    },
    {
      country: "373",
      countryIso: "MD",
      priority: 0,
      name: {
        eng: "Moldova",
      },
      coordinates: [],
    },
    {
      country: "377",
      countryIso: "MC",
      priority: 0,
      name: {
        eng: "Monaco",
      },
      coordinates: [],
    },
    {
      country: "976",
      countryIso: "MN",
      priority: 0,
      name: {
        eng: "Mongolia",
      },
      coordinates: [],
    },
    {
      country: "382",
      countryIso: "ME",
      priority: 0,
      name: {
        eng: "Montenegro",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "MS",
      priority: 999,
      name: {
        eng: "Montserrat",
      },
      coordinates: [],
    },
    {
      country: "212",
      countryIso: "MA",
      priority: 0,
      name: {
        eng: "Morocco",
      },
      coordinates: [],
    },
    {
      country: "258",
      countryIso: "MZ",
      priority: 0,
      name: {
        eng: "Mozambique",
      },
      coordinates: [],
    },
    {
      country: "95",
      countryIso: "MM",
      priority: 0,
      name: {
        eng: "Myanmar",
      },
      coordinates: [],
    },
    {
      country: "264",
      countryIso: "NA",
      priority: 0,
      name: {
        eng: "Namibia",
      },
      coordinates: [],
    },
    {
      country: "674",
      countryIso: "NR",
      priority: 0,
      name: {
        eng: "Nauru",
      },
      coordinates: [],
    },
    {
      country: "977",
      countryIso: "NP",
      priority: 0,
      name: {
        eng: "Nepal",
      },
      coordinates: [28.2479439867, 83.9162472404],
    },
    {
      country: "31",
      countryIso: "NL",
      priority: 0,
      name: {
        eng: "Netherlands",
      },
      coordinates: [],
    },
    {
      country: "687",
      countryIso: "NC",
      priority: 0,
      name: {
        eng: "New Caledonia",
      },
      coordinates: [],
    },
    {
      country: "64",
      countryIso: "NZ",
      priority: 0,
      name: {
        eng: "New Zealand",
      },
      coordinates: [],
    },
    {
      country: "505",
      countryIso: "NI",
      priority: 0,
      name: {
        eng: "Nicaragua",
      },
      coordinates: [],
    },
    {
      country: "227",
      countryIso: "NE",
      priority: 0,
      name: {
        eng: "Niger",
      },
      coordinates: [],
    },
    {
      country: "234",
      countryIso: "NG",
      priority: 0,
      name: {
        eng: "Nigeria",
      },
      coordinates: [],
    },
    {
      country: "683",
      countryIso: "NU",
      priority: 0,
      name: {
        eng: "Niue",
      },
      coordinates: [],
    },
    {
      country: "672",
      countryIso: "NF",
      priority: 0,
      name: {
        eng: "Norfolk Island",
      },
      coordinates: [],
    },
    {
      country: "850",
      countryIso: "KP",
      priority: 0,
      name: {
        eng: "North Korea",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "MP",
      priority: 999,
      name: {
        eng: "Northern Mariana Islands",
      },
      coordinates: [],
    },
    {
      country: "47",
      countryIso: "NO",
      priority: 0,
      name: {
        eng: "Norway",
      },
      coordinates: [],
    },
    {
      country: "968",
      countryIso: "OM",
      priority: 0,
      name: {
        eng: "Oman",
      },
      coordinates: [],
    },
    {
      country: "92",
      countryIso: "PK",
      priority: 0,
      name: {
        eng: "Pakistan",
      },
      coordinates: [],
    },
    {
      country: "680",
      countryIso: "PW",
      priority: 0,
      name: {
        eng: "Palau",
      },
      coordinates: [],
    },
    {
      country: "970",
      countryIso: "PS",
      priority: 0,
      name: {
        eng: "Palestine",
      },
      coordinates: [],
    },
    {
      country: "507",
      countryIso: "PA",
      priority: 0,
      name: {
        eng: "Panama",
      },
      coordinates: [],
    },
    {
      country: "675",
      countryIso: "PG",
      priority: 0,
      name: {
        eng: "Papua New Guinea",
      },
      coordinates: [],
    },
    {
      country: "595",
      countryIso: "PY",
      priority: 0,
      name: {
        eng: "Paraguay",
      },
      coordinates: [],
    },
    {
      country: "51",
      countryIso: "PE",
      priority: 0,
      name: {
        eng: "Peru",
      },
      coordinates: [],
    },
    {
      country: "63",
      countryIso: "PH",
      priority: 0,
      name: {
        eng: "Philippines",
      },
      coordinates: [11.7639871446, 122.874831814],
    },
    {
      country: "64",
      countryIso: "PN",
      priority: 999,
      name: {
        eng: "Pitcairn",
      },
      coordinates: [],
    },
    {
      country: "48",
      countryIso: "PL",
      priority: 0,
      name: {
        eng: "Poland",
      },
      coordinates: [],
    },
    {
      country: "351",
      countryIso: "PT",
      priority: 0,
      name: {
        eng: "Portugal",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "PR",
      priority: 999,
      name: {
        eng: "Puerto Rico",
      },
      coordinates: [],
    },
    {
      country: "974",
      countryIso: "QA",
      priority: 0,
      name: {
        eng: "Qatar",
      },
      coordinates: [],
    },
    {
      country: "242",
      countryIso: "CG",
      priority: 0,
      name: {
        eng: "Republic of the Congo",
      },
      coordinates: [],
    },
    {
      country: "262",
      countryIso: "RE",
      priority: 0,
      name: {
        eng: "Réunion",
      },
      coordinates: [],
    },
    {
      country: "40",
      countryIso: "RO",
      priority: 0,
      name: {
        eng: "Romania",
      },
      coordinates: [],
    },
    {
      country: "7",
      countryIso: "RU",
      priority: 0,
      name: {
        eng: "Russia",
      },
      coordinates: [],
    },
    {
      country: "250",
      countryIso: "RW",
      priority: 0,
      name: {
        eng: "Rwanda",
      },
      coordinates: [],
    },
    {
      country: "590",
      countryIso: "BL",
      priority: 999,
      name: {
        eng: "Saint Barthélemy",
      },
      coordinates: [],
    },
    {
      country: "290",
      countryIso: "SH",
      priority: 0,
      name: {
        eng: "Saint Helena",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "KN",
      priority: 999,
      name: {
        eng: "Saint Kitts and Nevis",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "LC",
      priority: 999,
      name: {
        eng: "Saint Lucia",
      },
      coordinates: [],
    },
    {
      country: "590",
      countryIso: "MF",
      priority: 999,
      name: {
        eng: "Saint Martin",
      },
      coordinates: [],
    },
    {
      country: "508",
      countryIso: "PM",
      priority: 0,
      name: {
        eng: "Saint Pierre and Miquelon",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "VC",
      priority: 999,
      name: {
        eng: "Saint Vincent and the Grenadines",
      },
      coordinates: [],
    },
    {
      country: "685",
      countryIso: "WS",
      priority: 0,
      name: {
        eng: "Samoa",
      },
      coordinates: [],
    },
    {
      country: "378",
      countryIso: "SM",
      priority: 0,
      name: {
        eng: "San Marino",
      },
      coordinates: [],
    },
    {
      country: "239",
      countryIso: "ST",
      priority: 0,
      name: {
        eng: "Sao Tome and Principe",
      },
      coordinates: [],
    },
    {
      country: "966",
      countryIso: "SA",
      priority: 0,
      name: {
        eng: "Saudi Arabia",
      },
      coordinates: [],
    },
    {
      country: "221",
      countryIso: "SN",
      priority: 0,
      name: {
        eng: "Senegal",
      },
      coordinates: [],
    },
    {
      country: "381",
      countryIso: "RS",
      priority: 0,
      name: {
        eng: "Serbia",
      },
      coordinates: [],
    },
    {
      country: "248",
      countryIso: "SC",
      priority: 0,
      name: {
        eng: "Seychelles",
      },
      coordinates: [],
    },
    {
      country: "232",
      countryIso: "SL",
      priority: 0,
      name: {
        eng: "Sierra Leone",
      },
      coordinates: [],
    },
    {
      country: "65",
      countryIso: "SG",
      priority: 0,
      name: {
        eng: "Singapore",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "SX",
      priority: 999,
      name: {
        eng: "Sint Maarten",
      },
      coordinates: [],
    },
    {
      country: "421",
      countryIso: "SK",
      priority: 0,
      name: {
        eng: "Slovakia",
      },
      coordinates: [],
    },
    {
      country: "386",
      countryIso: "SI",
      priority: 0,
      name: {
        eng: "Slovenia",
      },
      coordinates: [],
    },
    {
      country: "677",
      countryIso: "SB",
      priority: 0,
      name: {
        eng: "Solomon Islands",
      },
      coordinates: [],
    },
    {
      country: "252",
      countryIso: "SO",
      priority: 0,
      name: {
        eng: "Somalia",
      },
      coordinates: [],
    },
    {
      country: "27",
      countryIso: "ZA",
      priority: 0,
      name: {
        eng: "South Africa",
      },
      coordinates: [],
    },
    {
      country: "500",
      countryIso: "GS",
      priority: 999,
      name: {
        eng: "South Georgia and the South Sandwich Islands",
      },
      coordinates: [],
    },
    {
      country: "82",
      countryIso: "KR",
      priority: 0,
      name: {
        eng: "South Korea",
      },
      coordinates: [],
    },
    {
      country: "211",
      countryIso: "SS",
      priority: 0,
      name: {
        eng: "South Sudan",
      },
      coordinates: [],
    },
    {
      country: "34",
      countryIso: "ES",
      priority: 0,
      name: {
        eng: "Spain",
      },
      coordinates: [],
    },
    {
      country: "94",
      countryIso: "LK",
      priority: 0,
      name: {
        eng: "Sri Lanka",
      },
      coordinates: [],
    },
    {
      country: "249",
      countryIso: "SD",
      priority: 0,
      name: {
        eng: "Sudan",
      },
      coordinates: [],
    },
    {
      country: "597",
      countryIso: "SR",
      priority: 0,
      name: {
        eng: "Suriname",
      },
      coordinates: [],
    },
    {
      country: "47",
      countryIso: "SJ",
      priority: 999,
      name: {
        eng: "Svalbard and Jan Mayen",
      },
      coordinates: [],
    },
    {
      country: "268",
      countryIso: "SZ",
      priority: 0,
      name: {
        eng: "Swaziland",
      },
      coordinates: [],
    },
    {
      country: "46",
      countryIso: "SE",
      priority: 0,
      name: {
        eng: "Sweden",
      },
      coordinates: [],
    },
    {
      country: "41",
      countryIso: "CH",
      priority: 0,
      name: {
        eng: "Switzerland",
      },
      coordinates: [],
    },
    {
      country: "963",
      countryIso: "SY",
      priority: 0,
      name: {
        eng: "Syria",
      },
      coordinates: [],
    },
    {
      country: "886",
      countryIso: "TW",
      priority: 0,
      name: {
        eng: "Taiwan",
      },
      coordinates: [],
    },
    {
      country: "992",
      countryIso: "TJ",
      priority: 0,
      name: {
        eng: "Tajikistan",
      },
      coordinates: [],
    },
    {
      country: "255",
      countryIso: "TZ",
      priority: 0,
      name: {
        eng: "Tanzania",
      },
      coordinates: [],
    },
    {
      country: "66",
      countryIso: "TH",
      priority: 0,
      name: {
        eng: "Thailand",
      },
      coordinates: [],
    },
    {
      country: "670",
      countryIso: "TL",
      priority: 0,
      name: {
        eng: "Timor-Leste",
      },
      coordinates: [],
    },
    {
      country: "228",
      countryIso: "TG",
      priority: 0,
      name: {
        eng: "Togo",
      },
      coordinates: [],
    },
    {
      country: "690",
      countryIso: "TK",
      priority: 0,
      name: {
        eng: "Tokelau",
      },
      coordinates: [],
    },
    {
      country: "676",
      countryIso: "TO",
      priority: 0,
      name: {
        eng: "Tonga",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "TT",
      priority: 999,
      name: {
        eng: "Trinidad and Tobago",
      },
      coordinates: [],
    },
    {
      country: "216",
      countryIso: "TN",
      priority: 0,
      name: {
        eng: "Tunisia",
      },
      coordinates: [],
    },
    {
      country: "90",
      countryIso: "TR",
      priority: 0,
      name: {
        eng: "Turkey",
      },
      coordinates: [],
    },
    {
      country: "993",
      countryIso: "TM",
      priority: 0,
      name: {
        eng: "Turkmenistan",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "TC",
      priority: 999,
      name: {
        eng: "Turks and Caicos Islands",
      },
      coordinates: [],
    },
    {
      country: "688",
      countryIso: "TV",
      priority: 0,
      name: {
        eng: "Tuvalu",
      },
      coordinates: [],
    },
    {
      country: "256",
      countryIso: "UG",
      priority: 0,
      name: {
        eng: "Uganda",
      },
      coordinates: [],
    },
    {
      country: "380",
      countryIso: "UA",
      priority: 0,
      name: {
        eng: "Ukraine",
      },
      coordinates: [],
    },
    {
      country: "971",
      countryIso: "AE",
      priority: 0,
      name: {
        eng: "United Arab Emirates",
      },
      coordinates: [],
    },
    {
      country: "44",
      countryIso: "GB",
      priority: 0,
      name: {
        eng: "United Kingdom",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "US",
      priority: 0,
      name: {
        eng: "United States",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "UM",
      priority: 999,
      name: {
        eng: "United States Minor Outlying Islands",
      },
      coordinates: [],
    },
    {
      country: "598",
      countryIso: "UY",
      priority: 0,
      name: {
        eng: "Uruguay",
      },
      coordinates: [],
    },
    {
      country: "998",
      countryIso: "UZ",
      priority: 0,
      name: {
        eng: "Uzbekistan",
      },
      coordinates: [],
    },
    {
      country: "678",
      countryIso: "VU",
      priority: 0,
      name: {
        eng: "Vanuatu",
      },
      coordinates: [],
    },
    {
      country: "58",
      countryIso: "VE",
      priority: 0,
      name: {
        eng: "Venezuela",
      },
      coordinates: [],
    },
    {
      country: "84",
      countryIso: "VN",
      priority: 0,
      name: {
        eng: "Vietnam",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "VG",
      priority: 999,
      name: {
        eng: "Virgin Islands",
      },
      coordinates: [],
    },
    {
      country: "1",
      countryIso: "VI",
      priority: 999,
      name: {
        eng: "Virgin Islands of the United States",
      },
      coordinates: [],
    },
    {
      country: "681",
      countryIso: "WF",
      priority: 0,
      name: {
        eng: "Wallis and Futuna",
      },
      coordinates: [],
    },
    {
      country: "212",
      countryIso: "EH",
      priority: 999,
      name: {
        eng: "Western Sahara",
      },
      coordinates: [],
    },
    {
      country: "967",
      countryIso: "YE",
      priority: 0,
      name: {
        eng: "Yemen",
      },
      coordinates: [],
    },
    {
      country: "260",
      countryIso: "ZM",
      priority: 0,
      name: {
        eng: "Zambia",
      },
      coordinates: [],
    },
    {
      country: "263",
      countryIso: "ZW",
      priority: 0,
      name: {
        eng: "Zimbabwe",
      },
      coordinates: [],
    },
  ],
};
