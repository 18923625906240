import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
  Grid,
} from "@material-ui/core";
import { display, spacing } from "@material-ui/system";
import getAuthorizationToken from "../../api/GetAuthorizationToken";
import useAuth from "../../hooks/useAuth";
import stringData from "../../pages/stringFile";

import { useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../contexts/AuthContext";
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const styledButton = styled(Button)`
  background: #333e48;
`;

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [Loading, setLoading] = React.useState(false);
  const [messageOnChange, setChangeMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const authContext = useContext(AuthContext);

  const handleClose = () => {
    setChangeMessage(false);
  };

  const authentication = useMutation(
    ["authenticate"],
    async (data) =>
      await axios
        .post("https://test-adminapi.meetleelou.com/api/auth/token", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response: any) => {
          console.log(response.data);
          return response?.data;
        })
        .catch((e) => {
          return e;
        }),
    {
      onSuccess: (response: any) => {
        if (response?.token) {
          const authData = { ...response, userName: userName };
          localStorage.setItem("auth_session", JSON.stringify(authData));
          localStorage.setItem("leelou_aun", userName);
          authContext?.getAuthData();
          navigate("/");
        } else {
          setMessage(stringData?.login?.incorrectCredentials);
          setChangeMessage(true);
        }
      },
      onError: () => {
        setMessage(stringData?.login?.incorrectCredentials);
        setChangeMessage(true);
      },
    }
  );

  const authen = (data: any) => {
    authentication.mutate(data);
  };

  return (
    <div>
      <Formik
        initialValues={{
          username: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string()
            .max(255)
            .required(stringData.login.usernameRequired),
          password: Yup.string()
            .max(255)
            .required(stringData.login.passwordRequired),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setLoading(true);
          setUserName(values.username);
          const data = {
            username: values.username,
            password: values.password,
            platform: 1,
          };
          try {
            authen(data);
          } catch (error: any) {
            const message = error.message || "Failed to authenticate";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="text"
              name="username"
              label={stringData.login.username}
              value={values.username}
              error={Boolean(touched.username && errors.username)}
              fullWidth
              helperText={touched.username && errors.username}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              color="warning"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <TextField
              type="password"
              name="password"
              label={stringData.login.password}
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              color="warning"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="flex-start"
            >
              <Grid item>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={stringData.login.rememberme}
                />
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  to="/auth/reset-password"
                  fullWidth
                  color="primary"
                >
                  {stringData.login.forgotPassword}
                </Button>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems="flex-start"
              spacing={5}
            >
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="warning"
                  disabled={authentication?.isLoading}
                  size="large"
                  
                >
                  {authentication?.isLoading ? (
                      <CircularProgress color="inherit" size={24} />
                  ) : (
                    stringData.login.signIn
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <Dialog
        open={messageOnChange}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            {stringData.button.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SignIn;
