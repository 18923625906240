import React, { useState, useContext } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";

export interface DialogProps {
  open: boolean;
  title: string;
  description: string;
  buttons: ActionButtonProps[];
}

export interface ActionButtonProps {
  name: string;
  onClick: () => void;
}

function QuestionDialog() {
  const globalContext = useContext(GlobalContext);

  const handleClose = () => {
    globalContext?.setDisplayDialog({
      ...globalContext?.displayDialog,
      open: false,
    });
  };

  return (
    <Dialog
      open={globalContext?.displayDialog?.open || false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {globalContext?.displayDialog?.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {globalContext?.displayDialog?.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {globalContext?.displayDialog?.buttons.map(
          (action: ActionButtonProps) => {
            return (
              <Button
                onClick={() => {
                  action?.onClick();
                  handleClose();
                }}
                color={"primary"}
              >
                {action?.name}
              </Button>
            );
          }
        )}
        <Button onClick={handleClose} color={"error"}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default QuestionDialog;
