import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useQuery } from "@tanstack/react-query";
import { AuthContext } from "../../contexts/AuthContext";
import { grey } from "@material-ui/core/colors";

import {
  Avatar,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Select,
  Switch,
  TextField,
  Typography,
  Fab as MuiFab,
  IconButton as MuiIconButton,
  InputBase,
  IconButton,
  Chip,
  CardContent,
  FormHelperText,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  Button,
  CircularProgress
} from "@material-ui/core";
import Actions from "./Actions";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, GridSelectionModel} from '@mui/x-data-grid';
import { GlobalContext } from "../../contexts/GlobalContext";
import { spacing } from "@material-ui/system";
import { green, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Search as SearchIcon } from "react-feather";
import {
  Favorite as FavoriteIcon,
  StarHalf as StarHalfIcon,
  StarRate as StarRateIcon,
  Visibility,
  VisibilityOff,
  Delete
} from "@material-ui/icons";
import { useMutation } from "@tanstack/react-query";
import { SelectChangeEvent } from "@material-ui/core/Select";


const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;
const BigAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`;
const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Card = styled(MuiCard)(spacing);
const ExtraBigAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
`;
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Fab = styled(MuiFab)(spacing);
const iconColor = {
  orange: {
    color: "#ff8300",
  },
};
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 100%;
  }
`;

function Default() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [SearchTerm, setSearchTerm] = useState("");
  const authContext = useContext(AuthContext);
  const [Feedback, setFeedback] = useState([]);
  const [DefaultFeedback, setDefaultFeedback] = useState([]);
  const globalContext = useContext(GlobalContext);
  const [editMode, setEditMode] = useState(false);

  interface Feedbak {
    Id: string;
    Message: string;
    UserName: string;
    UserId: number;
    TimeStamp: number;
    Avatar: string;
    Priority: string;
    Status: string;
    MarkAsRead: boolean;
    Favorite: boolean;
  }

  const columns: GridColDef[] = [

  { field: 'UserName', headerName: 'User Name', width: 130, disableColumnMenu:true, 
  },
  { field: 'TimeStamp', headerName: 'Date', width: 150, disableColumnMenu:true, 
  },
  { field: 'Message', headerName: 'Message', width: 350, disableColumnMenu:true, 
  },
  {
    field: 'Priority',
    headerName: 'Priority',
    width: 120,
    sortable: false,
    disableColumnMenu:true,
    renderCell: (params: GridRenderCellParams) => {
      switch (params?.row?.Priority){
        case "High":
          return(     <Chip
            size="small"
            label="High"
            color="error"
          />)
        case "None":
          return(     <Chip
            size="small"
            label="None"
            color="default"
          />)
        case "Low":
          return(     <Chip
            size="small"
            label="Low"
            color="success"
          />)
        case "Medium":
          return(     <Chip
            size="small"
            label="Medium"
            color="warning"
          />)
        default:
          return(     <Chip
            size="small"
            label={params?.row?.Priority}
            color="default"
          />)
  }}},
  {
    field: 'Status',
    headerName: 'Status',
    width: 120,
    sortable: false,
    disableColumnMenu:true,
    renderCell: (params: GridRenderCellParams) => {
      switch (params?.row?.Status){
        case "Pending":
          return(     <Chip
            size="small"
            label="Pending"
            color="info"
          />)
        default:
          return(     <Chip
            size="small"
            label={params?.row?.Status}
            color="success"
          />)

  }}},

{
  field: 'MarkAsRead',
  headerName: 'Read',
  width: 120,
  sortable: false,
  disableColumnMenu:true,
  renderCell: (params: GridRenderCellParams) => (
    <IconButton size="large" color="warning"
    >
      {params?.row?.MarkAsRead? <Visibility color = {"warning"}/> : <VisibilityOff color={"disabled"}/>}
    </IconButton>
  ),
},
    {
      field: 'Favorite',
      headerName: 'Favorite',
      width: 120,
      sortable: false,
      disableColumnMenu:true,
      renderCell: (params: GridRenderCellParams) => (
          <FavoriteIcon color={params?.row?.Favorite?"warning":"disabled"}/>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 120,
      sortable: false,
      disableColumnMenu:true,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton size="large" color="warning"
        onClick={()=> globalContext?.setDisplayDialog({
          open: true,
          title: "Delete Feedback",
          description: "Are you sure you want to delete this feedback?",
          buttons: [{ name: "Yes", onClick: () => deleteFeedback?.mutate({id: params?.row?.Id}) }],
        }) }
        >
          <Delete color={'error'}/>
        </IconButton>
      ),
    },
  ];



const deleteFeedback = useMutation(
  ["deleteFeedback"],
  async (data:any) =>
    await axios
      .delete(`https://test-adminapi.meetleelou.com/api/Feedback/${data?.id}/feedback`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        return response?.data;
      })
      .catch((e) => {
        return e;
      }),
  {
    onSuccess: (response: any) => {
      if (response?.Status) {
          globalContext?.setDisplayQuickAlert({
              open: true,
              message: "Feedback has been deleted.",
            });
      } else {
          globalContext?.setDisplayQuickAlert({
              open: true,
              message: "Error occurred while deleting feedback.",
            });
      }
    },
    onError: () => {
      globalContext?.setDisplayQuickAlert({
          open: true,
          message: "Error occurred while deleting feedback.",
      });
    },
  }
);



  const updateFeedback = useMutation(
    ["updateFeedback"],
    async (data) =>
      await axios
        .put("https://test-adminapi.meetleelou.com/api/Feedback", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response: any) => {
          return response?.data;
        })
        .catch((e) => {
          return e;
        }),
    {
      onSuccess: (response: any) => {
        if (response?.Status) {
          setFeedback((prevRows:any) =>
            prevRows.map((row:Feedbak) =>
              row.Id === selectedFeedback?.Id ? { ...row, ...selectedFeedback } : row
            )
          );

            globalContext?.setDisplayQuickAlert({
                open: true,
                message: "Feedback has been updated.",
              });
        } else {
            globalContext?.setDisplayQuickAlert({
                open: true,
                message: "Error occurred while updating feedback.",
              });
        }
      },
      onError: () => {
        globalContext?.setDisplayQuickAlert({
            open: true,
            message: "Error occurred while updating feedback.",
        });
      },
    }
  );


  const { isLoading, refetch } = useQuery(
    ["get-feedbacks"],
    async () =>
      axios
        .get(`https://test-adminapi.meetleelou.com/api/Feedback`, {
          headers: { Authorization: `Bearer ${authContext?.auth?.token}` },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        }),
    {
      enabled: true,
      onSuccess: (response: any) => {
        setFeedback(response?.Data);
        setDefaultFeedback(response?.Data);
      },
      onError: () => {
        alert("Error occurred while loading Feedbacks");
      },
    }
  );

  const[selectedFeedback, setSelectedFeedback] = useState({} as any);
  
  const handlePriorityChange = (event: SelectChangeEvent) => {
    setSelectedFeedback((data:Feedbak) => ({...data, Priority:event.target.value as string}));
  };

  const handleMarkAsReadChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedFeedback((data:Feedbak) => ({...data, MarkAsRead:value=="0"?false:true}));

  };
  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedFeedback((data:Feedbak) => ({...data, Status:event.target.value as string}));

  };
  const handleFavoriteChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setSelectedFeedback((data:Feedbak) => ({...data, Favorite:value=="0"?false:true}));
  };

  const handleUpdatefeedback = () => {
    const updateData = {
      "Id": selectedFeedback.Id,
      "Priority": selectedFeedback.Priority,
      "Status": selectedFeedback.Status,
      "MarkAsRead": selectedFeedback.MarkAsRead,
      "Favorite": selectedFeedback.Favorite
    } as any;

    updateFeedback?.mutate(updateData);
  }
  return (
    <React.Fragment>
    <Helmet title="Feedbacks" />
    {/* <BigAvatar
                alt={String(key?.FirstName)}
                src={
                  "http://meetleeloudashboard.com.s3-website-ap-southeast-2.amazonaws.com/" +
                  String(key?.Avatar)
                }
              /> */}
    <Grid container style={{ height:"100%"}} spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        style={{ backgroundColor:"#fff"}} 
      >
        <Grid container spacing={2} px={2} style={{height:'5%'}}>
          <Grid item xs={8}>
            {/* <SearchField oc={handleChange} value={SearchTerm} /> */}
          </Grid>
          <Grid item xs={2}>
            {/* <FilterDialog data={DefaultUsers} callback={setUsers} /> */}
          </Grid>
          <Grid item xs={2} >
            {/* <NotificationOptionsDialog data={DefaultUsers} callback={setUsers} selectedRows={selectedRows}/> */}
        
          </Grid>
        </Grid>

        <Grid container spacing={0} px={2} style={{height:'95%'}}>
        <DataGrid
      rows={Feedback}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => row.Id}
      // checkboxSelection
      // disableSelectionOnClick
      onRowClick={(params:any)=>setSelectedFeedback(params?.row)}
      // onSelectionModelChange={handleSelectionChange}
      
    />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        sx={{
          flexDirection: "row",
          overflowY: "auto", // Enable vertical scrolling
          height: "100%", // Take full height of the parent
        }}
        spacing={2}
      >
        <Grid item xs={12}>

        
      {selectedFeedback?.Id ? (
         <Card sx={{ width: "100%" }} p={10}>
        <Grid container mb={10}>
          <Grid item xs={10} alignItems="center" flexDirection={"row"} display={"flex"}>
              <ExtraBigAvatar
                alt={selectedFeedback?.UserName}
                src={selectedFeedback?.Avatar}
              />
            <Grid
              style={{ display: "flex", justifyContent: "flex-start" }}
              direction="column"
              px={5}
            >
              <Typography variant="h6">
                {selectedFeedback?.UserName}

              </Typography>

                <Typography variant="body2" color={grey[500]} gutterBottom>
                User Id: {selectedFeedback?.UserId}
              </Typography>
            </Grid>
            </Grid>
  
          </Grid>
          <Typography variant="h6" mb={3}>
              Message

              </Typography>
          <Box style={{borderRadius:10, backgroundColor:"#f6f6f6"}} p={5} mb={10}>

          {selectedFeedback?.Message}
          </Box>
           <FormControl>
            <InputLabel id="priority-label">Priority</InputLabel>
            <Select
              labelId="priority-label"
              id="priority-label"
              value={selectedFeedback?.Priority}
              style={{width:100, margin:5}}
              onChange={handlePriorityChange}
            >

              <MenuItem value={"Low"}>Low</MenuItem>
              <MenuItem value={"Medium"}>Medium</MenuItem>
              <MenuItem value={"High"}>High</MenuItem>
              <MenuItem value={"None"}>None</MenuItem>

            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="priority-label">Mark as Read</InputLabel>
            <Select
              labelId="priority-label"
              id="priority-label"
              value={selectedFeedback?.MarkAsRead?"1":"0"}
              onChange={handleMarkAsReadChange}
              style={{width:100, margin:5}}

            >

              <MenuItem value={"1"}>True</MenuItem>
              <MenuItem value={"0"}>False</MenuItem>
     

            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="priority-label">Favorite</InputLabel>
            <Select
              labelId="priority-label"
              id="priority-label"
              value={selectedFeedback?.Favorite?"1":"0"}
              onChange={handleFavoriteChange}
              style={{width:100, margin:5}}

            >

              <MenuItem value={"1"}>True</MenuItem>
              <MenuItem value={"0"}>False</MenuItem>
     

            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="priority-label">Status</InputLabel>
            <Select
              labelId="priority-label"
              id="priority-label"
              value={selectedFeedback?.Status}
              onChange={handleStatusChange}
              style={{width:120, margin:5}}

            >

              <MenuItem value={"Pending"}>Pending</MenuItem>
              <MenuItem value={"Resolved"}>Resolved</MenuItem>
    
            </Select>
          </FormControl>
          <Grid container mt={5} >
          <Button
            type="submit"
            variant="contained"
            color="warning"
            size={"large"}
            disabled={updateFeedback?.isLoading}
            onClick={handleUpdatefeedback}
                >
                {updateFeedback?.isLoading?<CircularProgress color="inherit" size={24} />:"Update Feedback"}
                </Button>
                </Grid>

          </Card>
      ) : (
        <Card sx={{ width: "100%" }} pt={0}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
              p={5}
            >
              <CardContent>
                <Typography variant="h5" color={grey[500]}>
                  {"Choose feedback to view details."}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      )}
        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>
  );
}

export default Default;
