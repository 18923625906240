import React from "react";
import styled from "styled-components/macro";
import { CircularProgress, Typography, Box } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader() {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
      p={5}
    >
      <CircularProgress color="warning" size={25} />
      <Typography mt={3}>Loading...</Typography>
    </Box>
  );
}

export default Loader;
