import React from "react";
import styled from "styled-components/macro";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Fade,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Slide,
  Snackbar,
  SnackbarOrigin,
  Typography,
  ListProps,
  SlideProps,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { spacing } from "@material-ui/system";

const NavLink = React.forwardRef<ListProps, any>((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Button = styled(MuiButton)(spacing);

interface PositionedSnackbarState extends SnackbarOrigin {
  open: boolean;
}

function Notification(message: any) {
  const [state, setState] = React.useState<PositionedSnackbarState>({
    open: true,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState: SnackbarOrigin) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      message={message}
      key={vertical + horizontal}
    />
  );
}

export default Notification;
