import { createContext, useState, useEffect, ReactNode } from "react";

type TokenData = {
  token: string;
  expiration: string;
  expired: boolean;
  userName: string;
  checked: boolean;
};

type AuthData = {
  expired: boolean;
  token: string;
  expiration: string;
  checked: boolean;
};
type AuthContextType = {
  auth: AuthData;
  getAuthData: VoidFunction;
};
const AuthContext = createContext<AuthContextType | null>(null);
const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState<TokenData>({
    token: "",
    expiration: "",
    expired: false,
    userName: "",
    checked: false,
  });
  const getAuthData = () => {
    const loadedData = localStorage.getItem("auth_session");
    const data = JSON.parse(loadedData ? loadedData : "{}");
    const expirationDate = data?.expiration;
    const expired = hasExpired(expirationDate ? expirationDate : "");
    setAuth({ ...data, expired: expired, checked: true });
  };

  function hasExpired(expiration: string): boolean {
    const expirationDate: Date = new Date(expiration);
    const currentDate: Date = new Date();
    return expirationDate < currentDate;
  }

  useEffect(() => {
    getAuthData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        getAuthData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
