import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
const AuthCheck = ({ children }) => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (
      (!authContext?.auth.token || authContext?.auth?.expired) &&
      authContext?.auth?.checked
    ) {
      navigate("/auth/sign-in");
    }
  }, [authContext?.auth]);

  return <>{children}</>;
};

export default AuthCheck;
